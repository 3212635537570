import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallTasksCreateModalComponent } from './call-tasks-create-modal.component';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TimePickerModule } from 'app/components/time-picker/time-picker.module';
import { MatOptionModule } from '@angular/material/core';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';



@NgModule({
  declarations: [
    CallTasksCreateModalComponent
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    ColorPickerModule,
    NgxMatSelectSearchModule,
    TimePickerModule,
    MatOptionModule,
    FuseSidebarModule
  ]
})
export class CallTasksCreateModalModule { }
