import {
    Component,
    OnInit,
    ChangeDetectorRef,
    AfterViewChecked,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormControl,
    UntypedFormArray,
    UntypedFormBuilder,
    Validators,
} from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { Router } from '@angular/router';
import { DropdownsService, AuthenticationService } from 'app/services';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { startWith, tap, switchMap, takeUntil } from 'rxjs/operators';
import { QuestionairesComponent } from '../../questionaires/questionaires.component';
import { isEmpty, includes, forEach, filter, map, find } from 'lodash';
import { QuickAddComponent } from 'app/main/master-data/accounts/quick-add/quick-add.component';
import { TranslateService } from '@ngx-translate/core';
import { AccountsService } from 'app/services/accounts.service';
import { CheatSheetComponent } from '../../cheat-sheet/cheat-sheet.component';
import { GlobalFuntions } from 'app/_helpers';
import { CommonDialogComponent } from 'app/components/common-dialog/common-dialog.component';
import { BaseService } from 'app/_helpers/base/base.service';
import { GLOBALS } from 'app/config/globals';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { ViewingSheetComponent } from '../../viewing-sheet/viewing-sheet.component';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';
import { ComposeDialogComponent } from 'app/directives/compose-email/compose-dialog/compose-dialog.component';
import { QuickAddOwnersComponent } from 'app/main/master-data/owners/quick-add-owners/quick-add-owners.component';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
    selector: 'app-viewing-tours-form',
    templateUrl: './viewing-tours-form.component.html',
    styleUrls: ['./viewing-tours-form.component.scss'],
    providers: [GetCommercialReferencePipe]
})
export class ViewingToursFormComponent implements OnInit, AfterViewChecked {
    loadingLanguages = false;
    @Output() openTimeline: EventEmitter<any>;
    @Input() modal: any;
    @Input() sharedProperty: any;
    @Input() status: any;
    date = new Date().toISOString();
    searching: boolean;
    viewRecord: boolean;
    viewRecordActive: boolean;
    viewing: UntypedFormGroup;
    accountSearch$ = new Subject<string>();
    assigneeSearch: UntypedFormControl = new UntypedFormControl();
    companySearch$ = new Subject<string>();
    companyContactsSearch$ = new Subject<string>();
    searchOffices$ = new Subject<string>();
    contactSearch: UntypedFormControl = new UntypedFormControl();
    owners: any;
    properties: any;
    projects: any;
    projectSearch$ = new Subject<string>();
    propertySearch$ = new Subject<any>();
    comProperties: any;
    mooringsData: any;
    comPropertySearch$ = new Subject<string>();
    mooringSearch$ = new Subject<string>();
    boatsSearch$ = new Subject<string>();
    boatsData: any;
    accounts: any;
    languages: any;
    users: any;
    company: any;
    offices: any;
    contacts: any;
    companyContacts = [];
    list: UntypedFormArray;
    filter: any;
    comment = {} as boolean;
    searchDialogRef: any;
    user: any;
    officeData: any;
    userAgencyData: any;
    com = false as boolean;
    systemLang: any;
    propertiesIds = [] as any;
    commPropIds = [] as any;
    propertyIndex = 0;
    commercialIndex = 0;
    currentAgency: any;
    own = [] as any;
    cbSubAgency = false;
    // ticket #2874
    setValueViewingFor = 'Commercial Properties';
    relatedTo = 'activitie-viewing-tour';
    ListingAgencySearch: UntypedFormControl = new UntypedFormControl('');
    searchCompanyContacts: UntypedFormControl = new UntypedFormControl('');
    ownerSearch: UntypedFormControl = new UntypedFormControl('');
    companyContactList = [];
    ListingAgencyData: any;
    companyIndex: number;
    // allow_time_zone: boolean = true;

    private _unsubscribeAll: Subject<any>;
    currentUser: any;
    isMls = false;
    questionnaires = false;
    statusOptions = [];
    public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner
    curAgencyId: any;
    private _initialized = false;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
        private _authenticationService: AuthenticationService,
        private cdRef: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _snackbar: MatSnackBar,
        private _translateService: TranslateService,
        private _accountsService: AccountsService,
        public _globalFuntions: GlobalFuntions,
        private _gService: BaseService,
        public _crmMenuService: CrmMenuService,
        private reference: GetCommercialReferencePipe,
        private _timeLineData: TimeLineDataService,
        private _fuseSidebarService: FuseSidebarService,
    ) {
        this.openTimeline = new EventEmitter();
        this._unsubscribeAll = new Subject();
        this.user = this._authenticationService.currentUserValue;
        this.currentUser = this._authenticationService.currentUserValue;
        if (
            this.currentUser.user_role === 'mls_user' ||
            this.currentUser.user_role === 'mls_pro'
        ) {
            this.isMls = true;
        }
        this.officeData = this._authenticationService.currentOfficeValue;
        this.userAgencyData = this._authenticationService.currentAgencyValue;
        this.systemLang = _translateService.currentLang;
        if (
            !this.officeData.master_office &&
            this.officeData.is_cb &&
            this.userAgencyData.brochures_only
        ) {
            this.cbSubAgency = true;
            if (includes(this.router.url, 'commercials/')) {
                this.setValueViewingFor = 'Commercial Properties';
            }else if (includes(this.router.url, 'properties/')) {
                this.setValueViewingFor = 'Property';
            }
        }else {
            if(this._crmMenuService.isCommercialModule && this.userAgencyData.residential_properties) {
                this.setValueViewingFor = 'Commercial Properties';
            }else if(this.userAgencyData.residential_properties) {
                this.setValueViewingFor = 'Property';
            }else if(this._crmMenuService.isCommercialModule) {
                this.setValueViewingFor = 'Commercial Properties';
            }
        }
    }

    ngOnInit(): void {
        let outGoing = false;
        let inComing = false;
        if (this.currentUser.user_role === 'agent_portal') {
            this.statusOptions.push({
                key: 'Pending',
                value: 'Pending',
                disabled: false,
            });
            if (includes(this.router.url, 'edit/')) {
                this.statusOptions = [];
                this.statusOptions.push(
                    { key: 'Cancelled', value: 'Cancelled', disabled: false },
                    { key: 'Pending', value: 'Pending', disabled: true }
                );
            }
        } else {
            if (!this.sharedProperty) {
                this.statusOptions.push({
                    key: 'Booked',
                    value: 'Booked',
                    disabled: false,
                });
            }
            this.statusOptions.push(
                { key: 'Cancelled', value: 'Cancelled', disabled: false },
                { key: 'Completed', value: 'Completed', disabled: false },
                { key: 'Pending', value: 'Pending', disabled: false }
            );
        }
        if (this.sharedProperty) {
            this.statusOptions = [];
            this.statusOptions.push(
                { key: 'Pending', value: 'Pending', disabled: false }
            );
        }
        if (
            includes(this.router.url, 'view/') ||
            includes(this.router.url, 'edit/')
        ) {
            this.com = true;
        }
        if (includes(this.router.url, 'mls-dashboard') && this.status && this.status == 'Booked') {
            this.statusOptions = [];
            this.statusOptions.push(
                { key: 'Booked', value: 'Booked', disabled: false },
                { key: 'Cancelled', value: 'Cancelled', disabled: false },
            );
            outGoing = true;
        } else if (includes(this.router.url, 'mls-dashboard')) {
            this.statusOptions = [];
            this.statusOptions.push(
                { key: 'Pending', value: 'Pending', disabled: false },
                { key: 'Cancelled', value: 'Cancelled', disabled: false },
            );
            outGoing = true;
        }
        if (includes(this.router.url, 'dashboard/home')) {
            this.statusOptions = [];
            this.statusOptions.push(
                { key: 'Pending', value: 'Pending', disabled: false },
                { key: 'Cancelled', value: 'Cancelled', disabled: false },
                { key: 'Booked', value: 'Booked', disabled: false }
            );
            // inComing = true;
        }

        this.viewRecord = false;
        let officeRecord = false;
        if (includes(this.router.url, 'view/')) {
            this.viewRecord = true;
            officeRecord = true;
            this.viewRecordActive = true;
        }
        if (includes(this.router.url, 'properties/view/')) {
            this.viewRecord = false;
            officeRecord = true;
            this.viewRecordActive = false;
        }

        if (this._globalFuntions.getUserRole(this.user.user_role)) {
            officeRecord = true;
        }

       if(!this.viewing && !this._initialized){
        this.viewing = this.formBuilder.group({
            _id: [],
            agency: [],
            subject: [{ value: 'Viewing', disabled: this.viewRecord || outGoing || inComing}],
            create_account: [{ value: false, disabled: this.viewRecord || outGoing || inComing}],
            type: [{ value: 'viewing', disabled: this.viewRecord || outGoing || inComing}],
            account_name: [
                { value: '', disabled: this.viewRecordActive || outGoing || inComing},
                Validators.required,
            ],
            account: [{ value: '', disabled: this.viewRecord || outGoing || inComing}],
            language: [{ value: '', disabled: this.viewRecordActive || outGoing || inComing}],
            color: [{ value: '', disabled: this.viewRecord || outGoing || inComing}],
            assignee: [{ value: this.currentUser._id, disabled: this.viewRecord || outGoing || inComing}],
            status: [{ value: 'Pending', disabled: this.viewRecord }],
            date: [{ value: this.date, disabled: this.viewRecordActive || inComing}],
            end_date: [{ value: this.date, disabled: this.viewRecord || inComing}],
            start_date: [{ value: this.date, disabled: this.viewRecord || inComing}],
            start_time: [{ value: this.date, disabled: this.viewRecord || inComing}],
            end_time: [{ value: this.date, disabled: this.viewRecord || inComing}],
            google_map: [{ value: '', disabled: this.viewRecord || outGoing || inComing}],
            viewing_for: [{ value: this.setValueViewingFor, disabled: this.viewRecord || outGoing || inComing}],
            notification: this.formBuilder.group({
                type: [{ value: 'email', disabled: this.viewRecord || outGoing || inComing}],
                duration: [{ value: '30', disabled: this.viewRecord || outGoing || inComing}],
                time: [{ value: 'minutes', disabled: this.viewRecord || outGoing || inComing}],
            }),
            companies: [{ value: '', disabled: this.viewRecord || this.isMls || outGoing || inComing}],
            company_names: [{ value: '', disabled: this.viewRecord || outGoing || inComing}],
            description: [{ value: '', disabled: this.viewRecordActive || outGoing || inComing}],
            offices: [
                { value: [this.officeData._id], disabled: officeRecord || outGoing || inComing},
                Validators.required,
            ],
            contacts: [{ value: '', disabled: this.viewRecordActive || outGoing || inComing}],
            companies_contacts: [
                { value: '', disabled: this.viewRecord || this.isMls || outGoing || inComing},
            ],
            list: this.formBuilder.array([this.createList()]),
            agency_data: this.formBuilder.group({
                complete_address: this.formBuilder.group({
                    formatted_address: '',
                }),
            }),
            office_data: this.formBuilder.group({
                name: '',
            }),
        });
        this._initialized = true;
       }
        if (includes(this.router.url, 'moorings/')) {
            this.viewing.get('viewing_for').setValue('Moorings');
        } else if (includes(this.router.url, 'boats/')) {
            this.viewing.get('viewing_for').setValue('Boats');
        }
        this._translateService.onLangChange.subscribe(() => {
            this.systemLang = this._translateService.currentLang;
            this.loadLanguages();
        });
        this.list = this.viewing.get('list') as UntypedFormArray;
        this.currentAgency = this._authenticationService.currentAgencyValue;

        this.assigneeSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((search: string) => {
                    return this._dropdownsService.getUsersByAgencies(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.users = data.body;
            });

        this.loadLanguages();
        merge(
            this.accountSearch$,
            this.viewing.get('account_name').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((search: string) => {
                    if (!search) {
                        search = this.viewing.get('account_name').value;
                    }
                    if (!search) {
                        search = '';
                    }
                    return this._dropdownsService.getAccounts(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const accounts = map(data.body, (account) => {
                    if (account.type === 'company' && account.company_name) {
                        account.label = account.company_name;
                    } else if (account.forename || account.surname) {
                        account.label =
                            account.forename + ' ' + account.surname;
                    } else {
                        account.label = account.email;
                    }
                    account.full_name = !account?.full_name?.trim()
                        ? account.label
                        : account.full_name
                    return account;
                });
                this.accounts = accounts;
            });

        this.companySearch$
            .pipe(
                tap(() => (this.searching = true)),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getCompanySearch(
                        search,
                        this.viewing.get('companies').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.company = data.body;
            });

        this.companyContactsSearch$
            .pipe(
                tap(() => (this.searching = true)),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getCompanyContacts(
                        this.viewing.get('companies').value,
                        search
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companyContacts = data.body;
            });

        this.searchOffices$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });

        this.contactSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.contactSearch.value;
                    if (this.contactSearch.value) {
                        search = this.contactSearch.value;
                    }
                    return this._dropdownsService.getParticipantsViewings(
                        search
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.contacts = data.body;
            });

        this.ownerSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getOwners(search, '', false);
                })
            )
            .subscribe((data: any) => {
                filter(data.body, ['type', 'company']);
                forEach(data.body, (value, key) => {
                    if((value.type).trim().toLowerCase() == 'company'){
                        value.label = value.company_name;
                    }else{
                        value.label = value.full_name;
                    }                    
                });
                this.searching = false;
                this.owners = data.body;
            });

        this.propertySearch$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap((searchValue: string | number) => {
                    let search = '';
                    if (typeof searchValue === 'string') {
                        search = searchValue;
                    } else {
                        if (this.propertyIndex !== searchValue) {
                            this.propertyIndex = searchValue;
                        }
                    }
                    if (this.propertiesIds && this.propertiesIds.length > 0) {
                        return this._dropdownsService.getProperty(
                            search,
                            this.propertiesIds
                        );
                    } else {
                        const searchFilter = {} as any;
                        if (
                            this.viewing.get(
                                'list.' + this.propertyIndex + '.owner'
                            ).value
                        ) {
                            searchFilter.owner_id = this.viewing.get(
                                'list.' + this.propertyIndex + '.owner'
                            ).value;
                        }
                        return this._dropdownsService.getOwnersProperty(
                            search,
                            searchFilter
                        );
                    }
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                forEach(data.body, (value, key) => {
                    if (
                        this.currentAgency.pdf_external_ref ===
                        'external_reference' &&
                        value.agency_reference
                    ) {
                        value.label = value.agency_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref ===
                        'agency_reference' &&
                        value.external_reference
                    ) {
                        value.label = value.external_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref === 'reference'
                    ) {
                        value.label = value.reference;
                    } else {
                        value.label = value.reference;
                    }
                    if (value.property_type && value.property_type[this.systemLang]) {
                        value.label =
                            value.label + ' - ' + value.property_type[this.systemLang];
                    }
                });
                this.properties = data.body;
                this.propertiesIds = [];
            });

        this.projectSearch$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getConstructions(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                forEach(data, (value, key) => {
                    value.label =
                        value.reference +
                        '-' +
                        value.agency_reference +
                        '-' +
                        value.user_reference;
                });
                this.projects = data;
            });

        this.comPropertySearch$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap((searchValue: string | number) => {
                    const searchFilter = {} as any;
                    let search = '';
                    if (typeof searchValue === 'string') {
                        search = searchValue;
                    } else {
                        if (searchValue && this.commercialIndex !== searchValue) {
                            this.commercialIndex = searchValue;
                        }
                    }
                    if (this.commPropIds && this.commPropIds.length > 0) {
                        const queryOr = [];
                        // this will check is there is any value is empty or undefined
                        const filtered = this.commPropIds.filter(val => val === undefined || val === "");
                        if (filtered.length == 0) {
                            search = this.commPropIds;
                            queryOr.push({ reference: search });
                        } else {
                            queryOr.push({ reference: this.setQueryForCommProp() ? this.setQueryForCommProp() : ''});
                        }   
                        searchFilter.$or = queryOr;
                        return this._dropdownsService.getCommercialProperties(searchFilter);
                    }else {
                        let queryOr = [];
                        const commercialReference = this.viewing.get(`list.${this.commercialIndex}.commercial_reference`).value;
                        const ownerValue = this.viewing.get(`list.${this.commercialIndex}.owner`).value;
                        if (commercialReference) {
                            // If a commercial reference exists but no owner is selected, add the references
                            if (!ownerValue) {
                                queryOr.push({ reference: this.setQueryForCommProp() ? this.setQueryForCommProp() : '' });
                            }
                        } else {
                            queryOr.push({ reference: Number(search) });
                            ['external_reference', 'other_reference'].forEach(ref =>
                                queryOr.push({ [ref]: { $regex: `.*${search}.*`, $options: 'i' } })
                            );
                        }
                        if (queryOr.length)
                            searchFilter.$or = queryOr;
                        if (ownerValue) {
                            searchFilter.owner_id = ownerValue;
                            if (!searchFilter?.$or?.[0]?.reference) {
                                delete searchFilter.$or;
                            }
                        }
                        return this._dropdownsService.getCommercialProperties(searchFilter);
                    } 
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                if(data) {
                    for(const value of data.body) {
                        value.label = this.reference.transform(value) + 
                          ((value?.type_one_value) ?
                              ( ' - ' + value?.type_one_value[this.systemLang]) : '') + 
                          ((value?.location_value) ?
                          (' - ' + value?.location_value[this.systemLang]) : '')
                    }
                    this.comProperties = data.body;
                    if (this.commPropIds && this.commPropIds.length > 0) {
                        forEach(this.commPropIds, (value, key) => {
                            if(value != '') {
                                this.patchValueCommercialProperty(key, value).then()
                            }
                        });
                    }
                    this.commPropIds = [];
                }
            });

        this.mooringSearch$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((searchValue: string) => {
                    let search = '';
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;
                    if (searchValue) {
                        search = searchValue;
                        const queryOr = [];
                        queryOr.push({
                            reference: Number(search),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + search + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    }
                    return this._dropdownsService.getMoorings('mooring_properties', filter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;

                this.mooringsData = data.body;
            });

        this.boatsSearch$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;
                    if (searchValue) {
                        const queryOr = [];
                        search = searchValue;                
                        queryOr.push({
                            reference: Number(search),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + search + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    } 
                    return this._dropdownsService.getMoorings('boats', filter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.boatsData = data.body;
            });

        merge(this.ListingAgencySearch.valueChanges)
        .pipe(
            startWith(''),
            tap(() => this.searching = true),
            switchMap(() => {
                let search = '';
                if (this.ListingAgencySearch.value) {
                    search = this.ListingAgencySearch.value;
                }
                return this._dropdownsService.getListingAgency(search);
            })
        )
        .subscribe((data: any) => {
            this.ListingAgencyData = data.body;
            this.searching = false;
        }); 

        // searchCompanyContacts
        merge(this.searchCompanyContacts.valueChanges)
        .pipe(
            startWith(''),
            tap(() => (this.searching = true)),
            switchMap((searchValue: string) => {
                let search = '';
                let id = '';
                if (searchValue) {
                    search = searchValue;
                }
                if (this.companyIndex > -1) {
                    id = this.viewing.get('list.' + this.companyIndex + '.commercial_listing_agency_id').value;
                    }
                    return this._dropdownsService.getCompanyContacts(id,search);
                })
                )
            .subscribe((data: any) => {
                this.searching = false;
                this.companyContactList = data.body;
                if (this.companyIndex > -1) {
                    this.setConnercialAgencyContact(this.companyIndex, this.viewing.get('list.' + this.companyIndex + '.commercial_agency_contact_id').value).then();
                }
            });

        this.viewing.get('viewing_for').valueChanges.subscribe(() => {
            this.clearFormArray(this.list);
            this.list.push(this.createList());
        });
        if(includes(this.router.url, 'dashboard/home')) {
            this.viewing.get('agency').valueChanges.subscribe(() => {
                this.curAgencyId = this.viewing.get('agency').value;
                if(this.curAgencyId) {
                    this.addCompleteStatus();
                }
            });
        }

        /**
         * When the allow_time_zone parameter is set to true, time is rendered according to the server's time zone. 
         * Conversely, when the parameter is set to false, time is rendered according to the user's local time zone.
         * In edit mode, the time should initially be set according to the server's time zone when the patch is applied. 
         * However, if the user subsequently modifies the time, it should be updated to reflect the user's local time zone.
         * https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/3765#note_79063
         */
        // setTimeout(() => {
        //     this.allow_time_zone = false;
        // }, 3000);
    }

    loadAccount(id): void {
        this._dropdownsService
            .getAccounts('', id)
            .pipe(tap(() => (this.searching = true)))
            .subscribe((data: any) => {
                this.searching = false;
                this.accounts = data.body;
            });
    }

    createList(): UntypedFormGroup {
        return this.formBuilder.group({
            time: [{ value: '', disabled: this.viewRecord }],
            owner: [{ value: '', disabled: this.viewRecord }],
            lat: [{ value: '', disabled: this.viewRecord }],
            lng: [{ value: '', disabled: this.viewRecord }],
            listing_agency: [{ value: '', disabled: this.viewRecord }],
            non_own: [{ value: '', disabled: this.viewRecord }],

            property: [{ value: '', disabled: this.viewRecord }],
            property_title: [{ value: '', disabled: this.viewRecord }],
            property_reference: [{ value: '', disabled: this.viewRecord }],
            property_agency_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            property_external_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            property_address: [{ value: '', disabled: this.viewRecord }],
            commercial_name: [{ value: '', disabled: this.viewRecord }],
            listing_agent_name: [{ value: '', disabled: this.viewRecord }],

            project: [{ value: '', disabled: this.viewRecord }],
            project_title: [{ value: '', disabled: this.viewRecord }],
            project_reference: [{ value: '', disabled: this.viewRecord }],
            project_agency_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            project_user_reference: [{ value: '', disabled: this.viewRecord }],
            project_address: [{ value: '', disabled: this.viewRecord }],

            commercial_property: [{ value: '', disabled: this.viewRecord }],
            commercial_title: [{ value: '', disabled: this.viewRecord }],
            commercial_reference: [{ value: '', disabled: this.viewRecord }],
            commercial_other_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            commercial_external_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            commercial_address: [{ value: '', disabled: this.viewRecord }],
            // #3258 https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/3258#note_62810
            commercial_listing_agency_id: [{ value: '', disabled: this.viewRecord }],
            commercial_listing_agency_mobile: [{ value: '', disabled: this.viewRecord }],
            commercial_listing_agency_email: [{ value: '', disabled: this.viewRecord }],
            commercial_agency_contact_id: [{ value: '', disabled: this.viewRecord }],
            commercial_company_contact_id: [{ value: '', disabled: this.viewRecord }],
            commercial_agency_contact_email: [{ value: '', disabled: this.viewRecord }],
            commercial_agency_contact_mobile: [{ value: '', disabled: this.viewRecord }],
            
            comment: [{ value: '', disabled: this.viewRecord }],

            mooring_name: [{ value: '', disabled: this.viewRecord }],
            mooring: [{ value: '', disabled: this.viewRecord }],
            mooring_title: [{ value: '', disabled: this.viewRecord }],
            mooring_reference: [{ value: '', disabled: this.viewRecord }],
            mooring_other_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            mooring_external_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            mooring_address: [{ value: '', disabled: this.viewRecord }],

            boat_name: [{ value: '', disabled: this.viewRecord }],
            boat: [{ value: '', disabled: this.viewRecord }],
            boat_title: [{ value: '', disabled: this.viewRecord }],
            boat_reference: [{ value: '', disabled: this.viewRecord }],
            boat_other_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            boat_external_reference: [
                { value: '', disabled: this.viewRecord },
            ],
            boat_address: [{ value: '', disabled: this.viewRecord }],
        });
    }

    companyContact(): void {
        const ids = this.viewing.get('companies').value;
        this._dropdownsService
            .getCompanyContacts(ids)
            .subscribe((data: any) => {
                this.companyContacts = data.body;
            });
    }
    loadCompanies(): void {
        const ids = this.viewing.get('companies').value;
        this._dropdownsService
            .getCompanySearch('', ids)
            .subscribe((data: any) => {
                this.company = data.body;
            });
    }

    selectProperty(index: any): void {
        let _id: any;
        let event: any;
        _id = this.viewing.get('list.' + index + '.owner').value;
        if (_id) {
            event = filter(this.owners, ['_id', _id]);
            this.filter = {
                // owner_id: event[0]._id,
                references: event[0].properties,
            };
            if (this.setValueViewingFor === 'Property') {
                this.propertySearch$.next('');
            } else {
                this.commercialIndex = index;
                this.comPropertySearch$.next('');
            }
        } else {
            this.filter = {};
        }
        if (this.setValueViewingFor === 'Property') {
            this.viewing.get('list.' + index + '.property').setValue('');
            this.viewing.get('list.' + index + '.property_title').setValue('');
            this.viewing.get('list.' + index + '.commercial_name').setValue('');
            this.viewing
                .get('list.' + index + '.listing_agent_name')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing
                .get('list.' + index + '.property_address')
                .setValue('');
            this.viewing
                .get('list.' + index + '.property_external_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.property_agency_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.property_reference')
                .setValue('');
        } else {
            this.viewing.get('list.' + index + '.commercial_property').setValue('');
            this.viewing.get('list.' + index + '.commercial_title').setValue('');
            this.viewing.get('list.' + index + '.commercial_name').setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing
                .get('list.' + index + '.commercial_address')
                .setValue('');
            this.viewing
                .get('list.' + index + '.commercial_external_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.commercial_other_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.commercial_reference')
                .setValue('');
        }
    }

    onSelectProperty(index: any): void {
        let _id: any;
        let event: any;
        _id = this.viewing.get('list.' + index + '.property').value;
        if (_id) {
            event = filter(this.properties, ['_id', _id]);
            if(!event.length)
                return;
            const item = event[0];
            this.viewing.get('list.' + index + '.property_title').setValue('');
            this.viewing.get('list.' + index + '.commercial_name').setValue('');
            this.viewing
                .get('list.' + index + '.listing_agent_name')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing
                .get('list.' + index + '.property_reference')
                .setValue(item.reference);
            if (item.agency_reference) {
                this.viewing
                    .get('list.' + index + '.property_agency_reference')
                    .setValue(item.agency_reference);
            }
            if (item.external_reference) {
                this.viewing
                    .get('list.' + index + '.property_external_reference')
                    .setValue(item.external_reference);
            }
            if (typeof item.private_info_object !== 'undefined') {
                if (
                    typeof item.private_info_object[this.userAgencyData._id] !==
                    'undefined'
                ) {
                    if (
                        typeof item.private_info_object[this.userAgencyData._id]
                            .address !== 'undefined'
                    ) {
                        this.viewing
                            .get('list.' + index + '.property_address')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].address.formatted_address
                            );
                        this.viewing
                            .get('list.' + index + '.lat')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].address.lat
                            );
                        this.viewing
                            .get('list.' + index + '.lng')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].address.lng
                            );
                    }
                    if (
                        typeof item.private_info_object[this.userAgencyData._id]
                            .latitude !== 'undefined'
                    ) {
                        this.viewing
                            .get('list.' + index + '.lat')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].latitude
                            );
                    }
                    if (
                        typeof item.private_info_object[this.userAgencyData._id]
                            .longitude !== 'undefined'
                    ) {
                        this.viewing
                            .get('list.' + index + '.lng')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].longitude
                            );
                    }
                    if (
                        item.private_info_object[this.userAgencyData._id].owner
                    ) {
                        this.viewing
                            .get('list.' + index + '.owner')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].owner
                            );
                        // $scope.loadOwners('', index, $scope.viewing.list[index].owner);
                    }
                }
            }
            this.viewing
                .get('list.' + index + '.listing_agency')
                .setValue(item.listing_agency_name);
            if (!item.own) {
                this.viewing.get('list.' + index + '.non_own').setValue(true);
                this.own[index] = true;
            }
            if (item.listing_agent_name) {
                this.viewing
                    .get('list.' + index + '.listing_agent_name')
                    .setValue(item.listing_agent_name);
            }
            if (item.listing_agency_name) {
                this.viewing
                    .get('list.' + index + '.commercial_name')
                    .setValue(item.listing_agency_name);
            }
            if (item.property_name) {
                this.viewing
                    .get('list.' + index + '.property_title')
                    .setValue(item.property_name);
            } else if (
                this.systemLang === 'en' &&
                typeof item.title !== 'undefined'
            ) {
                this.viewing
                    .get('list.' + index + '.property_title')
                    .setValue(item.title.EN);
            } else if (typeof item.title !== 'undefined') {
                this.viewing
                    .get('list.' + index + '.property_title')
                    .setValue(item.title.ES);
            }
        } else {
            this.viewing.get('list.' + index + '.property_title').setValue('');
            this.viewing.get('list.' + index + '.commercial_name').setValue('');
            this.viewing
                .get('list.' + index + '.listing_agent_name')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.owner').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing
                .get('list.' + index + '.property_address')
                .setValue('');
            this.viewing
                .get('list.' + index + '.property_external_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.property_agency_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.property_reference')
                .setValue('');
        }
    }

    onSelectProject(index: any): void {
        let _id: any;
        let event: any;
        _id = this.viewing.get('list.' + index + '.project').value;
        if (_id) {
            event = filter(this.projects, ['_id', _id]);
            const item = event[0];
            this.viewing.get('list.' + index + '.project_title').setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing
                .get('list.' + index + '.project_reference')
                .setValue(item.reference);
            if (item.agency_reference) {
                this.viewing
                    .get('list.' + index + '.project_agency_reference')
                    .setValue(item.agency_reference);
            }
            if (item.external_reference) {
                this.viewing
                    .get('list.' + index + '.project_user_reference')
                    .setValue(item.user_reference);
            }
            if (typeof item.private_info_object !== 'undefined') {
                if (
                    typeof item.private_info_object[this.userAgencyData._id] !==
                    'undefined'
                ) {
                    if (
                        typeof item.private_info_object[this.userAgencyData._id]
                            .address !== 'undefined'
                    ) {
                        this.viewing
                            .get('list.' + index + '.project_address')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].address.formatted_address
                            );
                        this.viewing
                            .get('list.' + index + '.lat')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].address.lat
                            );
                        this.viewing
                            .get('list.' + index + '.lng')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].address.lng
                            );
                    }
                    if (
                        typeof item.private_info_object[this.userAgencyData._id]
                            .latitude !== 'undefined'
                    ) {
                        this.viewing
                            .get('list.' + index + '.lat')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].latitude
                            );
                    }
                    if (
                        typeof item.private_info_object[this.userAgencyData._id]
                            .longitude !== 'undefined'
                    ) {
                        this.viewing
                            .get('list.' + index + '.lng')
                            .setValue(
                                item.private_info_object[
                                    this.userAgencyData._id
                                ].longitude
                            );
                    }
                }
            }

            if(item?.project_name){
                this.viewing
                    .get('list.' + index + '.project_title')
                    .setValue(item.project_name);
            }else{
                if (this.systemLang === 'en' && typeof item.title !== 'undefined') {
                    this.viewing
                        .get('list.' + index + '.project_title')
                        .setValue(item.title.EN);
                } else if (typeof item.title !== 'undefined') {
                    this.viewing
                        .get('list.' + index + '.project_title')
                        .setValue(item.title.ES);
                }
            }
            this.viewing
                .get('list.' + index + '.listing_agency')
                .setValue(item.listing_agency_name);
        } else {
            this.viewing.get('list.' + index + '.project_title').setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing.get('list.' + index + '.project_address').setValue('');
            this.viewing
                .get('list.' + index + '.project_user_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.project_agency_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.project_reference')
                .setValue('');
        }
    }

    async onSelectCommercialProperties(index: any): Promise<void> {
        let _id: any;
        let event: any;
        _id = this.viewing.get('list.' + index + '.commercial_property').value;
        if (_id) {
            event = filter(this.comProperties, ['_id', _id]);
            if(!event.length)
                return;
            const item = event[0];
            if (!item.own) {
                this.viewing.get('list.' + index + '.non_own').setValue(true);
                this.own[index] = true;
            }
            this.viewing.get('list.' + index + '.commercial_title').setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.commercial_reference').setValue(item.reference);
            if (item.other_reference) {
                this.viewing
                    .get('list.' + index + '.commercial_other_reference')
                    .setValue(item.other_reference);
            }
            if (item.external_reference) {
                this.viewing
                    .get('list.' + index + '.commercial_external_reference')
                    .setValue(item.external_reference);
            }
            if (item.address && item.address.formatted_address) {
                this.viewing
                    .get('list.' + index + '.commercial_address')
                    .setValue(item.address.formatted_address);
                this.viewing
                    .get('list.' + index + '.lat')
                    .setValue(item.address.lat);
                this.viewing
                    .get('list.' + index + '.lng')
                    .setValue(item.address.lng);
            }
            if (item.latitude) {
                this.viewing
                    .get('list.' + index + '.lat')
                    .setValue(item.latitude);
            }
            if (item.longitude) {
                this.viewing
                    .get('list.' + index + '.lng')
                    .setValue(item.longitude);
            }

            // set Listing-agency-data
            if(item?.listing_agency_id && item?.listing_agency_id != '' && item?.listing_agency_data) {
                if(item?.listing_agency_data?._id) {
                    this.viewing.get('list.' + index + '.commercial_listing_agency_id').setValue(item?.listing_agency_data?._id);
                    this.setConnercialListingAgencyData(index,item?.listing_agency_data?._id).then()
                }
            }
            // set agency-contact-data
            if(item?.agency_contact && item?.agency_contact != '' && item?.agency_contact_data) {
                this.viewing.get('list.' + index + '.commercial_agency_contact_id').setValue(item?.agency_contact_data?._id)
                this.setCompanyIndex(index)
            }
            if (this.systemLang === 'en' && typeof item.title !== 'undefined') {
                this.viewing.get('list.' + index + '.commercial_title').setValue(item.title.EN);
            } else if (typeof item.title !== 'undefined') {
                this.viewing.get('list.' + index + '.commercial_title').setValue(item.title.ES);
            }
            this.viewing.get('list.' + index + '.listing_agency').setValue(item.listing_agency);
            if (
                item?.private_info_object &&
                item?.private_info_object[this.currentAgency._id]
            ) {
                let private_info_data = item.private_info_object[this.currentAgency._id];
                if (
                    private_info_data?.share_owners &&
                    private_info_data?.share_owners?.length &&
                    private_info_data?.share_owners['0']?.owner
                ) {
                    this.viewing.get('list.' + index + '.owner').setValue(private_info_data.share_owners['0'].owner);
                }
            }
        } else {
            this.viewing
                .get('list.' + index + '.commercial_title')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing
                .get('list.' + index + '.commercial_address')
                .setValue('');
            this.viewing
                .get('list.' + index + '.commercial_external_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.commercial_other_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.commercial_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.owner')
                .setValue('');
        }
    }

    onSelectMoorings(index: any): void {
        let _id: any;
        let event: any;
        _id = this.viewing.get('list.' + index + '.mooring').value;
        if (_id) {
            event = filter(this.mooringsData, ['_id', _id]);
            const item = event[0];
            if (!item.own) {
                this.viewing.get('list.' + index + '.non_own').setValue(true);
                this.own[index] = true;
            }
            this.viewing
                .get('list.' + index + '.mooring_title')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing
                .get('list.' + index + '.mooring_reference')
                .setValue(item.reference);
            if (item.other_reference) {
                this.viewing
                    .get('list.' + index + '.mooring_other_reference')
                    .setValue(item.other_reference);
            }
            if (item.external_reference) {
                this.viewing
                    .get('list.' + index + '.mooring_external_reference')
                    .setValue(item.external_reference);
            }
            if (item.address && item.address.formatted_address) {
                this.viewing
                    .get('list.' + index + '.mooring_address')
                    .setValue(item.address.formatted_address);
                this.viewing
                    .get('list.' + index + '.lat')
                    .setValue(item.address.lat);
                this.viewing
                    .get('list.' + index + '.lng')
                    .setValue(item.address.lng);
            }
            if (item.latitude) {
                this.viewing
                    .get('list.' + index + '.lat')
                    .setValue(item.latitude);
            }
            if (item.longitude) {
                this.viewing
                    .get('list.' + index + '.lng')
                    .setValue(item.longitude);
            }

            if (this.systemLang === 'en' && typeof item.title !== 'undefined') {
                this.viewing
                    .get('list.' + index + '.mooring_title')
                    .setValue(item.title.EN);
            } else if (typeof item.title !== 'undefined') {
                this.viewing
                    .get('list.' + index + '.mooring_title')
                    .setValue(item.title.ES);
            }
            this.viewing
                .get('list.' + index + '.listing_agency')
                .setValue(item.listing_agency);
        } else {
            this.viewing
                .get('list.' + index + '.mooring_title')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing
                .get('list.' + index + '.mooring_address')
                .setValue('');
            this.viewing
                .get('list.' + index + '.mooring_external_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.mooring_other_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.mooring_reference')
                .setValue('');
        }
    }
    onSelectBoats(index: any): void {
        let _id: any;
        let event: any;
        _id = this.viewing.get('list.' + index + '.boat').value;
        if (_id) {
            event = filter(this.boatsData, ['_id', _id]);
            const item = event[0];
            if (!item.own) {
                this.viewing.get('list.' + index + '.non_own').setValue(true);
                this.own[index] = true;
            }
            this.viewing
                .get('list.' + index + '.boat_title')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing
                .get('list.' + index + '.boat_reference')
                .setValue(item.reference);
            if (item.other_reference) {
                this.viewing
                    .get('list.' + index + '.boat_other_reference')
                    .setValue(item.other_reference);
            }
            if (item.external_reference) {
                this.viewing
                    .get('list.' + index + '.boat_external_reference')
                    .setValue(item.external_reference);
            }
            if (item.address && item.address.formatted_address) {
                this.viewing
                    .get('list.' + index + '.boat_address')
                    .setValue(item.address.formatted_address);
                this.viewing
                    .get('list.' + index + '.lat')
                    .setValue(item.address.lat);
                this.viewing
                    .get('list.' + index + '.lng')
                    .setValue(item.address.lng);
            }
            if (item.latitude) {
                this.viewing
                    .get('list.' + index + '.lat')
                    .setValue(item.latitude);
            }
            if (item.longitude) {
                this.viewing
                    .get('list.' + index + '.lng')
                    .setValue(item.longitude);
            }

            if (this.systemLang === 'en' && typeof item.title !== 'undefined') {
                this.viewing
                    .get('list.' + index + '.boat_title')
                    .setValue(item.title.EN);
            } else if (typeof item.title !== 'undefined') {
                this.viewing
                    .get('list.' + index + '.boat_title')
                    .setValue(item.title.ES);
            }
            this.viewing
                .get('list.' + index + '.listing_agency')
                .setValue(item.listing_agency);
        } else {
            this.viewing
                .get('list.' + index + '.boat_title')
                .setValue('');
            this.viewing.get('list.' + index + '.listing_agency').setValue('');
            this.viewing.get('list.' + index + '.lng').setValue('');
            this.viewing.get('list.' + index + '.lat').setValue('');
            this.viewing
                .get('list.' + index + '.boat_address')
                .setValue('');
            this.viewing
                .get('list.' + index + '.boat_external_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.boat_other_reference')
                .setValue('');
            this.viewing
                .get('list.' + index + '.boat_reference')
                .setValue('');
        }
    }

    getContact(user: any, contact: any, index: any): any {
        event.stopPropagation();
        let _id = this.viewing.get('list.' + index + '.property').value;
        let item = find(this.properties, ['_id', _id]);
        let countryCode = '';
        if(this.viewing.get('list.' + index + '.property').value != '') {
            _id = this.viewing.get('list.' + index + '.commercial_property').value;
            item = find(this.comProperties, ['_id', _id]);

            if(item?.listing_agency_id) {
                if(item?.listing_agency_data?.email && item?.listing_agency_data?.email != '') {
                    item.listing_agency_email = item?.listing_agency_data?.email
                }
                if(item?.listing_agency_data?.country_code && item?.listing_agency_data?.country_code != '') {
                    countryCode = item?.listing_agency_data?.country_code
                    countryCode = countryCode.replace(/[^\w\s]/gi, '');
                }
                if(item?.listing_agency_data?.phone && item?.listing_agency_data?.phone != '') {
                    countryCode = item.listing_agency_phone = countryCode + item?.listing_agency_data?.phone
                }else if(item?.listing_agency_data?.mobile && item?.listing_agency_data?.mobile != '') {
                    countryCode = item.listing_agency_phone = countryCode + item?.listing_agency_data?.mobile
                }
            }
            if(item?.agency_contact_data) {
                if(item?.agency_contact_data?.email && item?.agency_contact_data?.email != '') {
                    item.listing_agent_email = item?.agency_contact_data?.email;
                }
                if(item?.agency_contact_data?.phone && item?.agency_contact_data?.phone != '') {
                    item.listing_agent_phone = item?.agency_contact_data?.phone;
                }else if(item?.agency_contact_data?.mobile && item?.agency_contact_data?.mobile != '') {
                    item.listing_agent_phone = item?.agency_contact_data?.mobile;
                }
                if(item?.listing_agent_phone) {
                    item.listing_agent_phone = item.listing_agent_phone.replace(/[^\w\s]/gi, '');
                }
            }
        }
        if (user === 'agency') {
            if (contact === 'email' && item && item.listing_agency_email) {
                return item.listing_agency_email;
            } else if (contact === 'phone' && item && countryCode) {
                this.openCommonDialog(countryCode);
            } else {
                // this._snackbar.open(this._translateService.instant('contact not found'), this._translateService.instant('Close'),
                //     { duration: 2000 });
                return false;
            }
        } else {
            if (contact === 'email' && item && item.listing_agent_email) {
                return item.listing_agent_email;
            } else if (
                contact === 'phone' &&
                item &&
                item.listing_agent_phone
            ) {
                this.openCommonDialog(item.listing_agent_phone);
            } else {
                // this._snackbar.open(this._translateService.instant('contact not found'), this._translateService.instant('Close'),
                //     { duration: 2000 });
                return false;
            }
        }
    }

    openCommonDialog(phone: any): void {
        event.stopPropagation();
        const mobileData = {
            mobile: phone,
        };
        const dialog = this._matDialog.open(CommonDialogComponent, {
            disableClose: false,
            panelClass: 'common-dialog',
            data: mobileData,
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                }
            });
    }

    openQuestionaire(index?: any): void {
        const data = this.viewing.value as any;
        data.property_id = this.viewing.get(
            'list.' + index + '.property'
        ).value;
        data.status = this.viewing.get('status').value;
        this.searchDialogRef = this._matDialog.open(QuestionairesComponent, {
            panelClass: 'questionnaires-dialog',
            data: data,
        });
        this.searchDialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response.questions) {
                    this.questionnaires = true;
                }
            });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
    selectAccount(event): void {
        if (event) {
            this.viewing.get('account').setValue(event._id);
            this._accountsService
                .getById(event._id)
                .pipe(
                    tap(() => (this.loadingLanguages = true)),
                    takeUntil(this._unsubscribeAll)
                )
                .subscribe((data: any) => {
                    this.loadingLanguages = false;
                    if (data.language) {
                        this.viewing.get('language').setValue(data.language);
                    }
                });
            if (event.assigned_to && event.assigned_to.length) {
                const email = event.assigned_to[0];
                const user = find(this.users, ['email', email]);
                if (user) {
                    this.viewing.get('assignee').setValue(user._id);
                }
            }
        } else {
            this.viewing.get('account').reset();
        }
    }
    addAccount(event): void {
        event.stopPropagation();
        const dialogRef = this._matDialog.open(QuickAddComponent, {
            panelClass: 'event-form-dialog',
            disableClose: false,
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                if (result && result.full_name) {
                    this.viewing.get('account_name').setValue(result.full_name);
                    this.viewing.get('account').setValue(result._id);
                }
            });
    }
    viewAccount(event): void {
        event.stopPropagation();
        if (this.viewing.get('account').value) {
            window.open(
                '../master-data/accounts/update/' +
                this.viewing.get('account').value
            );
        } else {
            this._snackbar.open(
                this._translateService.instant('No account selected'),
                this._translateService.instant('Close'),
                { duration: 2000 }
            );
        }
    }

    clearFormArray = (formArray: UntypedFormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    };

    openViewSheet(data: any): void {
        this.searchDialogRef = this._matDialog.open(ViewingSheetComponent, {
            panelClass: 'event-form-dialog',
            data: data,
        });
        this.searchDialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                }
            });
    }

    openCheatSheet(data: any): void {
        this.searchDialogRef = this._matDialog.open(CheatSheetComponent, {
            panelClass: 'event-form-dialog',
            data: data,
        });
        this.searchDialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                }
            });
    }
    loadLanguages(): any {
        this._dropdownsService
            .getLanguages()
            .pipe(
                tap(() => (this.loadingLanguages = true)),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data: any) => {
                this.loadingLanguages = false;
                // this.languages = data.body;
                const languages = forEach(data.body, (element) => {
                    element.label = element.value[this.systemLang]
                        ? element.value[this.systemLang]
                        : '';
                    return element;
                });
                this.languages = languages;
            });
    }
    emailToOwner(propertyID): any {
        this.loaders.box = true;
        const post = {
            activity_id: this.viewing.value._id,
            property_id: propertyID,
            account_id: this.viewing.value.account,
        };
        this._gService
            .post(`viewings/email-questionnaires`, post, 'Yii')
            .subscribe(
                (res) => {
                    this.loaders.box = false;
                    this._gService.succussMessage(
                        'email has been sent successfully',
                        'message'
                    );
                },
                (error) => {
                    this._gService.errorMessage(
                        'problem sending mail',
                        'error'
                    );
                    this.loaders.box = false;
                }
            );
    }
    /**
     * 
     * @param index current list index number
     * @param reference which is commercial-property ref
     */
    async patchValueCommercialProperty (index: any, reference: any) {
        let pdata = find(this.comProperties, ['reference', reference]);
        this.viewing.get('list.' + index + '.commercial_property').setValue(pdata._id);
        this.onSelectCommercialProperties(index).then();
    }

    /**
     * 
     * @param index current list index number
     * @param id selected id of listing agency
     */
    async setConnercialListingAgencyData (index, id) {
        if(this.ListingAgencyData.length != 0) {
            let item = find(this.ListingAgencyData, ['_id', id]);
            if(item) {
                this.viewing.get('list.' + index + '.commercial_listing_agency_id').setValue(item._id)
                this.viewing.get('list.' + index + '.commercial_listing_agency_email').setValue(item.email)
                let countryCode = '';
                if(item?.country_code && item?.country_code != '') {
                    countryCode = item?.country_code;
                    countryCode = countryCode.replace(/[^\w\s]/gi, '');
                }
                if(item?.phone && item?.phone != '') {
                    countryCode = countryCode + item?.phone
                }else if(item?.mobile && item?.mobile != '') {
                    countryCode = countryCode + item?.mobile
                }
                this.viewing.get('list.' + index + '.commercial_listing_agency_mobile').setValue(countryCode)
            }
        }
    }

    /**
     * 
     * @param index current list index number
     * @param selectedId selected id of listing agency
     */
    async setConnercialAgencyContact(index, selectedId?:any) {
        let item: any;
        if(this.companyContactList.length != 0) {
            item = filter(this.companyContactList, ['_id', selectedId]);
            if(item && item.length !== 0) {
                item = item[0];
                this.viewing.get('list.' + index + '.commercial_agency_contact_id').setValue(item._id)
                this.setContactsToolTip(index,item);
            }
        }
    }

    /**
     * 
     * @param index current list index number
     * @param clearType are agency_contact if so remove only according to type
     */
    clearDataForm(index, clearType?:any) {
        this.commercialIndex = index;
        if(clearType == 'agency_contact') {
            this.viewing.get('list.' + index + '.commercial_agency_contact_id').setValue('')
            this.viewing.get('list.' + index + '.commercial_agency_contact_mobile').setValue('');
            this.viewing.get('list.' + index + '.commercial_agency_contact_email').setValue('');
        }else {
            this.viewing.get('list.' + index + '.commercial_listing_agency_id').setValue('')
            this.viewing.get('list.' + index + '.commercial_listing_agency_email').setValue('')
            this.viewing.get('list.' + index + '.commercial_listing_agency_mobile').setValue('')
            this.viewing.get('list.' + index + '.commercial_agency_contact_id').setValue('')
            this.viewing.get('list.' + index + '.commercial_agency_contact_mobile').setValue('');
            this.viewing.get('list.' + index + '.commercial_agency_contact_email').setValue('');
            this.viewing.get('list.' + index + '.non_own').setValue('');
        }
    }
    /**
     * 
     * @param id pass id to set email
     */
    composeEmail(id): void {
        event.stopPropagation();
        if(this.ListingAgencyData.length != 0) {
            let item = find(this.ListingAgencyData, ['_id', id]);
            if(!item) {
                item = find(this.companyContactList, ['_id', id]);
                this.relatedTo = 'activitie-agency-contact-viewing-tour'
            }
            if (item) {    
                this._matDialog.open(ComposeDialogComponent, {
                    panelClass: 'mail-compose-dialog',
                    disableClose: false,
                    data: {
                        to: item.email ? [item.email] : [],
                        relatedTo: this.relatedTo
                    }
                });
            }
        }
    }

    /**
     * 
     * @param index current list index number
     * @param item data of agency-contact 
     */
    setContactsToolTip(index,item) {
        let mobile_num = ''
        if(item?.name) {
            this.viewing.get('list.' + index + '.listing_agent_name').setValue(item?.name); 
        }
        if(item?.email && item?.email != '') {
            item.listing_agent_email = item?.email;
            this.viewing.get('list.' + index + '.commercial_agency_contact_email').setValue([item.listing_agent_email]);
        }
        if(item?.phone && item?.phone != '') {
            mobile_num = item.listing_agent_phone = item?.phone;
        }else if(item?.mobile && item?.mobile != '') {
            mobile_num = item.listing_agent_phone = item?.mobile;
        }
        this.viewing.get('list.' + index + '.commercial_agency_contact_mobile').setValue(mobile_num);
        this.viewing.get('list.' + index + '.commercial_agency_contact_email').setValue(item.email);
    }

    setCompanyIndex(i: any): any {
        this.companyIndex = i;
        this.searchCompanyContacts.setValue('');
    }
    setQueryForCommProp() {
        let refArr = [];
        if(this.viewing.get('list') && this.viewing.get('list').value && this.viewing.get('list').value.length > 0) {
            forEach(this.viewing.get('list').value, (value, key) => {
                if(value.commercial_reference && value.commercial_reference != '') {
                    refArr.push(value.commercial_reference);
                }
            });
            if(typeof refArr != 'undefined') {
                return refArr;
            }
        }
    }

    getTooltipHtmlPhones(id: any) {
        let data: any;
    
        data = find(this.owners, ['_id', id]);
    
        let html = '';
    
        if (data && (data.home_phone) && data.home_phone !== '') {
            html += `${this._translateService.instant('home_phone')}: <a href="tel:${data.home_phone}">${data.home_phone}</a> `;
        }
        if (data && (data.work_phone) && data.work_phone !== '') {
            html += `${this._translateService.instant('work_phone')}: <a href="tel:${data.work_phone}">${data.work_phone}</a> `;
        }
        if (data && (data.mobile_phone) && data.mobile_phone !== '') {
            html += `${this._translateService.instant('mobile_phone')}: <a href="tel:${data.mobile_phone}">${data.mobile_phone}</a> `;
    
            html += `<br>`;
    
        }
        if (data && (data.mobile) && data.mobile !== '') {
            html += `${this._translateService.instant('mobile_phone')}: <a href="tel:${data.mobile}">${data.mobile}</a> `;
        }
        if (data && !isEmpty(data.partners) && data.partners.length) {
            forEach(data.partners, (partner) => {
                if (!isEmpty(partner.phone)) {
                    html += `${this._translateService.instant('Partners Phones')}: <a href="tel:${partner.phone}">${partner.phone}</a> <br>`;
                }
            });
        }
        return html;
    
    }

    openOnwerContactDialog(id: any): any {
        let data: any;
        data = find(this.owners, ['_id', id]);
        const mobileData = {
            type: 'owner',
            _id: data._id,
            mobile: data.mobile,
        };
        const dialog = this._matDialog.open(CommonDialogComponent, {
            disableClose: false,
            panelClass: 'common-dialog',
            data: mobileData
        });
        dialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    data.whatsapp = true;
                    this._timeLineData.setModelDataValue(data);
                    this._timeLineData.setRelatedToValue('owner');
                    this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
                }
            });
    }

    showOwnerInfo(data): any {
        const ownerCreateDialog = this._matDialog.open(QuickAddOwnersComponent, {
          panelClass: 'event-form-dialog',
          disableClose: false,
          data: data
        });
    }

    composeOwnerEmail(owner): void {
        if (owner) {
            this._dropdownsService.getPropertyOwners(owner)
                .subscribe((ownerData: any) => {
                    this._matDialog.open(ComposeDialogComponent, {
                        panelClass: 'mail-compose-dialog',
                        disableClose: false,
                        data: {
                            to: ownerData.body[0].email ? [ownerData.body[0].email] : [],
                            relatedTo: 'owner'
                        }
                    });
                });
        }
    }
    // this function set the date and time
    onChildRefresh(index): void {
        if(index >= 0) {
            const date = new Date();
            const time = this.viewing.get('list.'+index+'.time').value;
            const split = time.split(":");
            const H = split[0] * 1;
            const M = split[1] * 1;
            date?.setHours(H);
            date?.setMinutes(M);
            date?.setMilliseconds(0);
            this.viewing.get('list.'+index+'.time').setValue(date);
        }
    }

    addCompleteStatus() {
        if(this.currentAgency._id == this.curAgencyId) {
            this.statusOptions.push({ key: 'Completed', value: 'Completed', disabled: false })
        }
    }
}
