import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'inactive-confirmation',
  templateUrl: './inactive-confirmation.component.html',
  styleUrls: ['./inactive-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InactiveConfirmationComponent implements OnInit {

  reason: string;
  relatedTo: string = '';
  inactiveMoreDetail: string = '';   // Add a property for the textarea content
  private inputSubject = new Subject<Event>();

  reasonLists = [
    {message: 'Not interested at this time', type : "both"},
    {message: 'Has already bought', type : "both"},
    {message: 'Other reason (comment below)', type : "both"},
    {message: 'inactive_price', type : "account"},
    {message: 'inactive_personal', type : "account"},
    {message: 'inactive_financing', type : "account"},
    {message: 'inactive_more_info', type : "account"},
  ];
  
  constructor(
    public dialogRef: MatDialogRef<InactiveConfirmationComponent>,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public _data: any = {}
  ) { 
    if (this._data && this._data.hasOwnProperty('relatedTo')) {
      this.relatedTo = this._data.relatedTo;
    }
  }

  ngOnInit(): void {
    this.inputSubject
    .pipe(debounceTime(300)) // Wait 300ms after user stops typing
    .subscribe((event: Event) => {
      const textarea = event.target as HTMLTextAreaElement;
      this.inactiveMoreDetail = textarea.value;
    });
  }

  onUserTyping(event: Event): void {
    this.inputSubject.next(event);
  }

  onConfirm(): void {
    let reason = this._translateService.instant(this.reason);
    if(this.inactiveMoreDetail != '') {
      reason = reason +" "+ this.inactiveMoreDetail
    }
    this.dialogRef.close(reason)
  }

  ngOnDestroy(): void {
    this.inputSubject.unsubscribe(); // Cleanup subscription
  }

}
