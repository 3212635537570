import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFuntions } from 'app/_helpers';
import { AuthenticationService, DropdownsService, TasksService } from 'app/services';
import { AccountsService } from 'app/services/accounts.service';
import { OwnersService } from 'app/services/owners.service';
import * as _ from 'lodash';
import { Observable, Subject, forkJoin, identity, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-call-tasks-create-modal',
  templateUrl: './call-tasks-create-modal.component.html',
  styleUrls: ['./call-tasks-create-modal.component.scss']
})
export class CallTasksCreateModalComponent {
  data: any = {};
  toggle = false as boolean;
  isClick = false as boolean;
  public searching: boolean;
  borderColor: string;
  activityForm: FormGroup;
  task: FormArray;
  relatedToOptions: { value: string; label: string; }[];
  date = new Date().toISOString();
  viewRecord = false;
  user: any;
  officeData: any;
  accounts = [] as any;
  filter: any;
  owners = [] as any;
  chiltItems: Observable<any[]>;
  accountSearch$ = new Subject<string>();
  ownerSearch$ = new Subject<string>();
  searchAssignedTo$ = new Subject<string>();
  currentAgency: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<CallTasksCreateModalComponent>,
    private _tasksService: TasksService,
    public _globalFuntions: GlobalFuntions,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private formBuilder: FormBuilder,
    private _dropdownsService: DropdownsService,
    private _accountsService: AccountsService,
    private _ownersService: OwnersService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this.user = this._authenticationService.currentUserValue;
    this.officeData = this._authenticationService.currentOfficeValue;
    this.currentAgency = this._authenticationService.currentAgencyValue;

    this.filter = {};
    this.filter.options = {
      page: 1,
      limit: 1000,
      sort: {
        value: 1
      }
    };
    this.filter.query = {
      status: 'Active'
    };
    this._unsubscribeAll = new Subject();

    if (!_.isEmpty(this._data)) {
      this.data = this._data;
    }
  }

  ngOnInit(): void {
    this.relatedToData();

    this.searchAssignedTo$
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        takeUntil(this._unsubscribeAll),
        switchMap((search: string) => {
          return this._dropdownsService.getAllAgencyUsers(search);
        }),
      )
      .subscribe((data: any) => {
        _.forEach(data, (value) => {
          value.label = _.isEmpty(_.trim(value.full_name)) ? value.username : value.full_name;
        });
        this.chiltItems = data;
      });

    this.viewRecord = false;
    let officeRecord = false;

    if (this._globalFuntions.getUserRole(this.user.user_role)) {
      officeRecord = true;
    }

    let time = null;
    time = new Date();
    time.setMinutes(0, 0, 0);
    time.setHours(time.getHours() + 1); // Round up to the next hour
    time = time.toISOString();

    this.activityForm = this.formBuilder.group({
      _id: [],
      subject: [{ value: '', disabled: this.viewRecord }],
      priority: [{ value: 'Normal', disabled: this.viewRecord }],
      status: [{ value: 'Open', disabled: this.viewRecord }],
      start_date: [{ value: this.date, disabled: this.viewRecord }],
      start_time: [{ value: time, disabled: this.viewRecord }],
      end_date: [{ value: this.date, disabled: this.viewRecord }],
      end_time: [{ value: time, disabled: this.viewRecord }],
      notification: this.formBuilder.group({
        type: [{ value: 'email', disabled: this.viewRecord }],
        duration: [{ value: '30', disabled: this.viewRecord }],
        time: [{ value: 'minutes', disabled: this.viewRecord }],
      }),
      assignee_user: [{ value: 'None', disabled: this.viewRecord }],
      calendar_id: [{ value: 'None', disabled: this.viewRecord }],
      dependent_step: [{ value: 'None', disabled: this.viewRecord }],
      milestone: [{ value: 'None', disabled: this.viewRecord }],
      related_steps: [{ value: 'None', disabled: this.viewRecord }],
      sequence_id: [{ value: 'None', disabled: this.viewRecord }],
      steps: [{ value: 'None', disabled: this.viewRecord }],
      repeate_type: [{ value: 'None', disabled: this.viewRecord }],
      description: [{ value: '', disabled: this.viewRecord }],
      offices: [{ value: [this.officeData._id], disabled: officeRecord }, Validators.required],
      from: [{ value: this.user.user_name, disabled: this.viewRecord }],
      assignee: [{ value: [this.user._id], disabled: this.viewRecord }],
      task: this.formBuilder.array([this.createTask()]),
      type: [{ value: 'task', disabled: false }],
      task_to_call: [{ value: true, disabled: this.viewRecord }],
    });

    this.task = this.activityForm.get('task') as FormArray;
    this.currentAgency = this._authenticationService.currentAgencyValue;


    this.accountSearch$
      .pipe(
        startWith(''),
        tap(() => this.searching = true),
        switchMap((searchValue: string) => {
          let search = '';
          if (searchValue) {
            search = searchValue;
          }
          return this._dropdownsService.getAccounts(search);
        })
      )
      .subscribe((data: any) => {
        this.searching = false;
        const accounts = _.map(data.body, (element) => {
          if (element.type === 'company') {
            element.label = element.company_name;
          } else {
            element.label = element.full_name;
          }
          return element;
        });
        this.accounts = accounts;
      });

    this.ownerSearch$
      .pipe(
        startWith(''),
        tap(() => this.searching = true),
        switchMap((searchValue: string) => {
          let search = '';
          if (searchValue) {
            search = searchValue;
          }
          return this._dropdownsService.getOwners(search, '', false);
        })
      )
      .subscribe((data: any) => {
        this.searching = false;
        const owners = _.map(data.body, (element) => {
          if (element.type == 'company') {
            element.label = element.company_name;
          } else {
            element.label = element.full_name;
          }
          return element;
        });
        this.owners = owners;
      });

    if (this.data?.relatedTo === 'accounts' && this.data.accountId && this.data.accountName) {
      this.updateTaskAndList('Account', this.data.accountId, this.data.accountName, this.accounts);
    } else if (this.data?.relatedTo === 'owners' && this.data.ownerId && this.data.ownerName) {
      this.updateTaskAndList('Owner', this.data.ownerId, this.data.ownerName, this.owners);
    }

  }

  updateTaskAndList(relatedTo: string, id: string, name: string, list: any[]) {
    const taskArray = this.activityForm.get('task') as FormArray;
    const entity = { _id: id, label: name };
    list.push(entity);

    if (taskArray.length > 0) {
      const taskGroup = taskArray.at(0) as FormGroup;
      taskGroup.patchValue({
        related_to: relatedTo,
        [relatedTo.toLowerCase()]: id,
      });
    }
  }

  createTask(): FormGroup {
    return this.formBuilder.group({
      related_to: [{ value: '', disabled: this.viewRecord }, Validators.required],
      account: [{ value: '', disabled: this.viewRecord }],
      owner: [{ value: '', disabled: this.viewRecord }],
    });
  }

  submitForm(): void {
    if (this.activityForm.valid) {
      if (this.borderColor) {
        this.activityForm.get('color').setValue(this.borderColor);
      }
      if (this.activityForm.get('start_date').value) {
        this.activityForm.get('end_date').setValue(this.activityForm.get('start_date').value);
      }
      if (this.activityForm.get('start_time').value) {
        this.activityForm.get('end_time').setValue(this.activityForm.get('start_time').value);
      }
      if (this.activityForm.get('task').value) {
        const tasks = this.activityForm.get('task').value;

        this.constructTaskSubject(tasks).pipe(
          switchMap(subject => {
            if (!_.isEmpty(subject)) {
              const title = this._translateService.instant('call_title') + ' ' + subject;  //format : call + full_name + phone
              this.activityForm.get('subject').setValue(_.trim(title));
            }
            this.isClick = true;
            return this._tasksService.create(_.pickBy(this.activityForm.getRawValue(), identity));
          })
        ).subscribe(
          () => {
            this.matDialogRef.close('refresh');
            this._snackBar.open(this._translateService.instant('Created successfully'), this._translateService.instant('Close'), {
              duration: 2000,
            });
          },
          () => { this.isClick = false; }
        );
      } else {
        this.activityForm.markAllAsTouched();
      }
    } else {
      //mark touched
      this.activityForm.markAllAsTouched();
    }
  }

  constructTaskSubject(tasks: any) {
    const subjectObservables = tasks.map(task => {
      if (task.related_to === 'Account') {
        const accountId = task.account;
        return this._accountsService.getById(accountId).pipe(
          map((data: any) => {
            let subjectPart = '';
            if (data && data.hasOwnProperty('full_name') && !_.isEmpty(data.full_name)) {
              subjectPart += _.trim(data.full_name) + ' ';
            }
            if (data && data.hasOwnProperty('mobile_phone') && !_.isEmpty(data.mobile_phone) && _.isArray(data.mobile_phone)) {
              subjectPart += data.mobile_phone.join(' ');
            }
            return subjectPart;
          })
        );
      } else if (task.related_to === 'Owner') {
        const ownerId = task.owner;
        return this._ownersService.getById(ownerId).pipe(
          map((data: any) => {
            let subjectPart = '';
            if (data && data.hasOwnProperty('full_name') && !_.isEmpty(data.full_name)) {
              subjectPart += _.trim(data.full_name) + ' ';
            }
            if (data && data.hasOwnProperty('mobile') && !_.isEmpty(data.mobile) && _.isArray(data.mobile)) {
              subjectPart += data.mobile.join(' ');
            } else if (data && data.hasOwnProperty('mobile') && !_.isEmpty(data.mobile) && _.isString(data.mobile)) {
              subjectPart += data.mobile;
            }
            return subjectPart;
          })
        );
      } else {
        return of('');
      }
    });

    return forkJoin(subjectObservables).pipe(
      map(subjectParts => subjectParts.join(' '))
    );
  }

  getInvalidControls(formGroup: FormGroup): string[] {
    const invalidControls: string[] = [];
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control.invalid) {
        invalidControls.push(controlName);
      }
    });
    return invalidControls;
  }

  relatedToData() {
    this.relatedToOptions = [
      { value: 'Account', label: 'Account' },
      { value: 'Owner', label: 'Owner' },
    ];
    this.relatedToTranslateLabels();
  }

  relatedToTranslateLabels() {
    this.relatedToOptions.forEach(option => {
      option.label = this._translateService.instant(option.label);
    });
    this.relatedToOptions.sort((a, b) => a.label.localeCompare(b.label));
  }

  hasContactAccess(id: string, relatedTo: string): boolean {
    let result = true;
    if (['agent', 'admin_agent_light'].includes(this.user.user_role)) {
      let email = this.user.hasOwnProperty('email') ? this.user.email : (this.user.hasOwnProperty('user_email') ? this.user.user_email : '');
      if (id) {
        let data: any;
        if (relatedTo == 'Account' && this.accounts) {
          data = this.accounts.find(x => x._id == id);
        } else if (relatedTo == 'Owner' && this.owners) {
          data = this.owners.find(x => x._id == id);
        }
        if (data) {
          if (
            data.hasOwnProperty('assign_agent') &&
            Array.isArray(data.assign_agent) &&
            data.assign_agent.length &&
            data.assign_agent.includes(this.user._id)) {
            result = true;
          }
          if (
            data.hasOwnProperty('assigned_to') &&
            Array.isArray(data.assigned_to) &&
            data.assigned_to.length &&
            data.assigned_to.includes(email)
          ) {
            result = true;
          }
        }
      }
    }
    return result;
  }
}
