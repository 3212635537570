import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { isEmpty, indexOf } from 'lodash';
import { AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';


@Directive({
    selector: '[ifIsMls]'
})
export class IfIsMlsDirective {
    data: any;
    currentUser: any;
    @Input('ifIsMls')
    set _data(data: any) {
        data = !isEmpty(data) ? data : {};
        this.data = data;
        this.setConditions();
    }
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private _authenticationService: AuthenticationService
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
    }

    private setConditions(): void {
        let exceptionalAgencies = [environment.casafariAgency];
        if (
            this.currentUser &&
            this.currentUser.user_role !== 'admin' &&
            this.data.agency &&
            indexOf([
                this.currentUser.user_agency,
                environment.roAgency,
                environment?.roCostaBlancaAgency,
                environment.roCostaDelSolAgency,
                environment.habihubAgency,
                environment.habihubCBAgency,
                environment.habihubCDSAgency
            ], this.data.agency) < 0
        ) {
            this.renderer.addClass(this.el.nativeElement, 'hidden');
        }
        else if (
            this.currentUser &&
            this.data.hidde_for &&
            this.data.path &&
            indexOf(this.data.hidde_for, this.currentUser.user_role) > -1
        ) {
            if (
                this.data.path === './accounts' ||
                this.data.path === './reports' ||
                this.data.path === './history' ||
                this.data.path === './calendar' ||
                this.data.path === './activities/active' ||
                this.data.path === './mail' ||
                this.data.path === './sequences'
            ) {
                this.renderer.addClass(this.el.nativeElement, 'hidden');
            }
        }
        else if (
            this.currentUser &&
            this.data.hidde_for &&
            indexOf(this.data.hidde_for, this.currentUser.user_role) > -1
        ) {
            this.renderer.addClass(this.el.nativeElement, 'hidden');
        }
        /**
         * exceptional case
         * https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/3761#note_78725
         */
        if (this.data?.exceptional_for && exceptionalAgencies.includes(this.data.exceptional_for)) {
            this.renderer.removeClass(this.el.nativeElement, 'hidden');
        }
        
    }

}
