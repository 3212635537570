// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tab-id .media {
  position: relative;
  margin: 0 16px 16px 0;
  border-radius: 4px;
  overflow: hidden;
}
tab-id .media .preview {
  display: block;
  height: 100%;
}
tab-id .media .title {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
  color: #ff0000;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/main/master-data/accounts/accounts-create/tabs/tab-id/tab-id.component.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;AAAR;AAEQ;EACI,cAAA;EACA,YAAA;AAAZ;AAKQ;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AAHZ","sourcesContent":["tab-id {\n    .media {\n        position: relative;\n        margin: 0 16px 16px 0;\n        border-radius: 4px;\n        overflow: hidden;\n\n        .preview {\n            display: block;\n            height: 100%;\n            // width: 256px;\n            // height: 256px;\n        }\n\n        .title {\n            position: absolute;\n            top: 2px;\n            right: 2px;\n            z-index: 10;\n            color: #ff0000;\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
