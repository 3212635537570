import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit,AfterViewInit, HostListener } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { navigation } from 'app/navigation/navigation';
import { AutoLogoutService } from 'app/_helpers/auto-logout.service';
import { forEach, identity, includes, map, pickBy } from 'lodash';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MlsWizardComponent } from './components/mls-wizard/mls-wizard.component';
import { RenewSubscriptionComponent } from './components/renew-subscription/renew-subscription.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SharingWarningComponent } from './components/sharing-warning/sharing-warning.component';
import { SystemMessagesComponent } from './components/system-messages/system-messages.component';
import { AuthenticationService, UserService } from './services';
import { CrmMenuService } from './services/crm-menu.service';
import { WebsocketService } from './services/websocket.service';
import { AppProviders } from './_helpers';




@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    currentUser: any;
    resetPwd = 0;
    warningRef: any;
    mlsWizardRef: any;
    isScrolled = false;
    lastScrollTop = 0;
    isBrowser: boolean;
    

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _authenticationService: AuthenticationService,
        private _appProviders: AppProviders,
        private cdRef: ChangeDetectorRef,
        private _crmMenuService: CrmMenuService,
        private _adapter: DateAdapter<any>,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _userService: UserService,
        private router: Router,
        private _autoLogoutService: AutoLogoutService,
        private socket: WebsocketService
    ) {
        // Get Browser lang
        let appLang = 'en';
        if (includes(this._appProviders.siteLangIds, this._translateService.getBrowserLang())) {
            appLang = this._translateService.getBrowserLang();
        }
        if (localStorage.getItem('lang')) {
            appLang = localStorage.getItem('lang');
        } else if (this._translateService.currentLang) {
            appLang = this._translateService.currentLang;
        }
        appLang = appLang === 'es' ? 'es_AR' : appLang;
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(this._appProviders.siteLangIds);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Use a language
        this._translateService.use(appLang);

        // for date picker
        this._adapter.setLocale(appLang);

        // to detect every route Change And Page refresh

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            if (event.url !== '/login' && !router.navigated) {
                // socket event to join streams 
                this.socket.emit('connectToBoardStreams', {
                    _id: this._authenticationService?.currentUserValue?._id,
                    name: this._authenticationService?.currentUserValue?.full_name
                });
                this.socket.emit('unReadNotificationCount', { userId: this._authenticationService?.currentUserValue?._id });
                this.socket.emit('getAllNotifications', { userId: this._authenticationService?.currentUserValue?._id });
            }
        });

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        // /**
        //  * If you are using a language other than the default one, i.e. Turkish in this case,
        //  * you may encounter an issue where some of the components are not actually being
        //  * translated when your app first initialized.
        //  *
        //  * This is related to ngxTranslate module and below there is a temporary fix while we
        //  * are moving the multi language implementation over to the Angular's core language
        //  * service.
        //  **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        // /**
        //  setTimeout(() => {
        //     this._translateService.setDefaultLang('en');
        //     this._translateService.setDefaultLang('tr');
        //  });
        //  */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: any) => {
                this.fuseConfig = config;
                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }
                // Color theme - Use normal for loop for IE11 compatibility
                // for (let i = 0; i < this.document.body.classList.length; i++) {
                //     const className = this.document.body.classList[i];
                //     if (className.startsWith('theme-')) {
                //         this.document.body.classList.remove(className);
                //     }
                // }
                for (const list of this.document.body.classList) {
                    if (list.startsWith('theme-')) {
                        this.document.body.classList.remove(list);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        this._authenticationService.currentUser
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                this.currentUser = user;
                this.setNavigation();
                if (user?.user_role !== 'admin' && user?.user_agency_data?.subscription_paid !== undefined && !user?.user_agency_data.subscription_paid) {
                    const dialogRef = this._matDialog.open(RenewSubscriptionComponent, {
                        panelClass: 'subscription-renew-dialog',
                        disableClose: true,
                        data: user
                    });
                    dialogRef.afterClosed().subscribe((res) => {
                        if (res) {
                            this.router.navigate(['/agencies/update/' + user?.user_agency_data?._id]);
                        } else {
                            this._authenticationService.logout();
                        }
                    });
                } else if (user) {
                    this._crmMenuService.getMenuStructure()
                        // .pipe(takeUntil(this._unsubscribeAll))
                        .then((menu: any) => {
                            if (user?.user_office_data?.is_cb && user?.user_role === 'agent_portal') {
                                forEach(menu, (element) => {
                                    if (element?.title === 'Activities') {
                                        forEach(element?.children, (data, key) => {
                                            if (data?.title === 'Sales') {
                                                element?.children.splice(parseInt(key), 1);
                                            }
                                        });
                                    }
                                });
                            }
                            this.navigation = menu;
                            this.setNavigation(this.navigation, 'main');
                            this._translateService.reloadLang(user.default_lang);
                        });

                    if (user.password_expire && this.resetPwd === 0) {
                        this.resetPwd = 1;
                        this._userService.getById(this.currentUser._id).subscribe((data: any) => {
                            setTimeout(() => {
                                const dialogRef = this._matDialog.open(ResetPasswordComponent, {
                                    panelClass: 'reset-pwd-dialog',
                                    disableClose: true,
                                    data: data
                                });

                                dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
                                    if (response) {
                                        response = pickBy(response.value, identity);
                                        this._userService.update(response)
                                            .subscribe(() => {
                                                this._matSnackBar.open(this._translateService.instant('Password changes successfully'), this._translateService.instant('Close'), {
                                                    duration: 2000,
                                                });
                                            });
                                    }
                                });
                            }, 3000);
                        });

                    }
                    this.systemMessages();
                }
            });

        merge(this._authenticationService.currentAgency, this._authenticationService.currentUser)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                const currentAgency = this._authenticationService.currentAgencyValue;
                const currentUser = this._authenticationService.currentUserValue;
                if (
                    !this.warningRef &&
                    currentAgency &&
                    currentAgency.warning_count &&
                    currentAgency.warning_count > 0 &&
                    currentUser &&
                    (currentUser.user_role === 'mls_user' || currentUser.user_role === 'mls_pro')
                ) {
                    this.warningRef = this._matDialog.open(SharingWarningComponent, {
                        panelClass: 'sharing-warning',
                    });
                    this.warningRef.afterClosed().subscribe(() => {
                        this.warningRef = undefined;
                    });
                }
                setTimeout(() => {
                    if (this.router.url !== '/register?update=true') {
                        if (
                            !this.mlsWizardRef &&
                            currentAgency &&
                            currentAgency.mls_wizard !== false &&
                            currentUser &&
                            (currentUser.user_role === 'mls_user' || currentUser.user_role === 'mls_pro')
                        ) {
                            this.mlsWizardRef = this._matDialog.open(MlsWizardComponent, {
                                panelClass: 'mls-wizard',
                                disableClose: true,
                            });
                            this.mlsWizardRef.afterClosed().subscribe(() => {
                                setTimeout(() => {
                                    this.mlsWizardRef = undefined;
                                }, 5000);
                            });
                        }
                    }
                }, 3000);
            });

        /** 
         * @todo need to improve this 
         * need to see if we can set these settings only at start and not 
         * on every route change
         */
        this.router.events
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((val: any) => {
                this._authenticationService.currentOffice
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((office: any) => {
                        if (this.router.url.indexOf('offices') !== 1) {
                            if (office && office.theme_options) {
                                this._fuseConfigService.setConfig(office.theme_options);
                                this.cdRef.detectChanges();
                            }
                        }
                    });
            });
    }

    ngAfterViewInit(): void {
        if (this._platform.IOS) {
          setTimeout(() => {
            window.scrollTo(0, 1);
          }, 200);
    
          this.adjustViewportHeight();
          window.addEventListener('resize', this.adjustViewportHeight);
          window.addEventListener('orientationchange', this.adjustViewportHeight);
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
      if (window.scrollY > 10) {
        this.document.body.classList.add('scrolled');
      } else {
        this.document.body.classList.remove('scrolled');
      }
    }

    /**
     * On destroysrc/styles.scss
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        window.removeEventListener('resize', this.adjustViewportHeight);
        window.removeEventListener('orientationchange', this.adjustViewportHeight);
    }

    private adjustViewportHeight(): void {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.document.body.style.height = `${window.innerHeight}px`;
    }

    private setNavigation(navigation = [], menuKey = 'main') {
        this._fuseNavigationService.unregister(menuKey);
        this._fuseNavigationService.register(menuKey, navigation);
        this._fuseNavigationService.setCurrentNavigation(menuKey);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    systemMessages(): void {
        this._userService.getSystemMessages()
            .subscribe((data: any) => {
                if (data.body && data.body.length && !this.mlsWizardRef) {
                    this.mlsWizardRef = this._matDialog.open(SystemMessagesComponent, {
                        panelClass: 'system-messages',
                        disableClose: true,
                    });
                    this.mlsWizardRef.afterClosed().subscribe(() => {
                        this.mlsWizardRef = undefined;
                    });
                }
            });
    }
}
