import { NgModule } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { GetPropertyReferencePipe } from './get-property-reference.pipe';
import { GetConstructionReferencePipe } from './get-construction-reference.pipe';
import { GetPropertyPricePipe } from './get-property-price.pipe';
import { GetPropertyTitlePipe } from './get-property-title.pipe';
import { GetCommercialReferencePipe } from './get-commercial-reference.pipe';
import { GetCommercialPricePipe } from './get-commercial-price.pipe';
import { GetCommercialTitlePipe } from './get-commercial-title.pipe';
import { GetPropertyTerracesPipe } from './get-property-terraces.pipe';
import { GetImageSrcPipe } from './get-image-src.pipe';
import { OrderTicketAttachmentsPipe } from './order-ticket-attachments.pipe';
import { GroupbyTicketAttachmentsPipe } from './groupby-ticket-attachments.pipe';
import { SortPropertyDataPipe } from './sort-property-data.pipe';
import { GetPropertyDescriptionPipe } from './get-property-description.pipe';
import { GetPropertyAddressPipe } from './get-property-address.pipe';
import { GetFormattedPricePipe } from './get-formatted-price.pipe';
import { LimitToPipe } from './limit-to.pipe';
import { SortByPipe } from './sort-by.pipe';
import { SafePipe } from './safe.pipe';
import { GetCommercialAddressPipe } from './get-commercial-address.pipe';
import { MonthDateFormatterPipe } from './month-date-formatter.pipe';
import { GetRentalPropertyTitlePipe } from './get-rental-property-title.pipe';
import { GetBoatMooringUrlPipe } from './get-boat-mooring-url.pipe';
import { GetPropertyUrlPipe } from './get-property-url.pipe';
import { GetCommercialPropertyTerracesPipe } from './get-commercial-property-terraces.pipe';
import { GetCommercialPropertyUrlPipe } from './get-commercial-property-url.pipe';
import { DateTimePipe } from './date-time.pipe';

@NgModule({
  declarations: [
    GetPropertyReferencePipe,
    GetConstructionReferencePipe,
    GetPropertyPricePipe,
    GetPropertyTitlePipe,
    GetCommercialReferencePipe,
    GetCommercialPricePipe,
    GetCommercialTitlePipe,
    GetPropertyTerracesPipe,
    GetImageSrcPipe,
    OrderTicketAttachmentsPipe,
    GroupbyTicketAttachmentsPipe,
    SortPropertyDataPipe,
    GetPropertyDescriptionPipe,
    GetPropertyAddressPipe,
    GetCommercialAddressPipe,
    GetFormattedPricePipe,
    LimitToPipe,
    SortByPipe,
    SafePipe,
    MonthDateFormatterPipe,
    GetRentalPropertyTitlePipe,
    GetBoatMooringUrlPipe,
    GetPropertyUrlPipe,
    GetCommercialPropertyTerracesPipe,
    GetCommercialPropertyUrlPipe,
    DateTimePipe
  ],
  imports: [],
  exports: [
    GetPropertyReferencePipe,
    GetConstructionReferencePipe,
    GetPropertyPricePipe,
    GetPropertyTitlePipe,
    GetCommercialReferencePipe,
    GetCommercialPricePipe,
    GetCommercialTitlePipe,
    GetPropertyTerracesPipe,
    GetImageSrcPipe,
    OrderTicketAttachmentsPipe,
    GroupbyTicketAttachmentsPipe,
    SortPropertyDataPipe,
    GetPropertyDescriptionPipe,
    GetPropertyAddressPipe,
    GetCommercialAddressPipe,
    GetFormattedPricePipe,
    LimitToPipe,
    SortByPipe,
    SafePipe,
    MonthDateFormatterPipe,
    GetRentalPropertyTitlePipe,
    GetBoatMooringUrlPipe,
    GetPropertyUrlPipe,
    GetCommercialPropertyTerracesPipe,
    GetCommercialPropertyUrlPipe,
    DateTimePipe
  ],
  providers: [
    CurrencyPipe
  ]
})
export class AppPipesModule { }
