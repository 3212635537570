import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from './authentication.service';
import { isEmpty, map, isArray, includes } from 'lodash';
import { GlobalFuntions, AppProviders } from 'app/_helpers';
import { map as mapped } from 'rxjs/operators';
import { Router } from '@angular/router';

interface feesystem { id: string, fiscal_name: string }
@Injectable({
    providedIn: 'root'
})
export class DropdownsService {

    currencies: Array<object> = [
        { key: 'EUR', value: 'EUR' },
        { key: 'GBP', value: 'GBP' },
        { key: 'USD', value: 'USD' },
        { key: 'AED', value: 'AED' },
        { key: 'JPY', value: 'JPY' },
        { key: 'TRY', value: 'TRY' },
        { key: 'RON', value: 'RON' },
        { key: 'IDR', value: 'IDR' }
    ];
    
    feedSystemsArr: Array<feesystem> = [
        { id: environment.casafariAgency, fiscal_name: "Casafari - Baleares" },
        { id: environment.casafariPortugalAgency, fiscal_name: "Casafari - Portugal" },
        { id: environment.roAgency, fiscal_name: "Resales Online" },
        { id: environment.roCostaBlancaAgency, fiscal_name: "Resales Online Costa Blanca" },
        { id: environment.habihubAgency, fiscal_name: "HABIHUB-CC" },
        { id: environment.habihubCBAgency, fiscal_name: "HABIHUB-CB" },
        { id: environment.habihubCDSAgency, fiscal_name: "HABIHUB-CDS" }
    ];

    currentLang: any;
    currentUser: any;
    siteLanguages: any;

    constructor(
        private http: HttpClient,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _globalFuntions: GlobalFuntions,
        private _appProviders: AppProviders,
        private router: Router
    ) {
        // this._translateService.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.currentUser = this._authenticationService.currentUserValue;
        this.siteLanguages = this._appProviders.siteLanguages;
    }

    getCountries(search: string | number, excludeExcluded: boolean = false): Observable<object> {
        const filter = { query: {} as any, options: { page: 1, limit: 300, sort: {} } };
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search) {
            filter.query.key = { $in: search };
        }
        filter['excludeExcluded'] = excludeExcluded;
        return this.http.post(environment.apiUrl + 'countries', this._globalFuntions.clearObject(filter));
    }
    getAllCountries(search: string | number): Observable<object> {
        const filter = { query: {} as any, options: { page: 1, limit: 300, sort: {} } };
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search) {
            filter.query.key = { $in: search };
        }
        return this.http.post(environment.apiUrl + 'countries', filter);
    }
    getRegions(search: string | number, country?: number | object, excludeExcluded: boolean = false): Observable<object> {
        const filter = { query: {} as any, options: { page: 1, limit: 300, sort: {} } as any };
        filter.options.populate = [{ path: 'country_data' }];
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        } 
        if (typeof country === 'number') {
            filter.query.country = country ? country : 0;
        }else if (typeof country === 'object' && !isEmpty(country)) {
            filter.query.country = { $in: country };
        }
        if (typeof search === 'object' && search) {
            filter.query.key = { $in: search };
        }
        filter['excludeExcluded'] = excludeExcluded;
        filter['index'] = excludeExcluded;
        return this.http.post(environment.apiUrl + 'regions', this._globalFuntions.clearObject(filter));
    }
    getProvinces(search: string | number, country?: number | boolean | object, region?: number, excludeExcluded: boolean = false): Observable<object> {
        const filter = { query: {} as any, options: { page: 1, limit: 300, sort: {} } as any };
        filter.options.populate = [{ path: 'region_data' }];
        if (isArray(search) && search.length) {
            filter.options.limit = search.length;
        }
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search) {
            filter.query.key = { $in: search };
        }
        if (typeof country === 'number') {
            filter.query.country = country ? country : 0;
        } else if (typeof country === 'object' && !isEmpty(country)) {
            filter.query.country = { $in: country };
        }
        if (region) {
            filter.query.region = region;
        }
        filter['excludeExcluded'] = excludeExcluded;
        filter['index'] = excludeExcluded;
        return this.http.post(environment.apiUrl + 'provinces', this._globalFuntions.clearObject(filter));
    }
    getCities(
        search: string | number | object, 
        country?: number | boolean | object, 
        region?: number | boolean, 
        province?: number | object | boolean,
        excludeExcluded: boolean = false
    ): Observable<object> {
        const filter = { query: {} as any, options: { page: 1, limit: 300, sort: {} } as any };
        filter.options.populate = [{ path: 'province_data' }];
        if (isArray(search) && search.length) {
            filter.options.limit = search.length;
        }
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search) {
            filter.query.key = { $in: search };
        }
        if (typeof country === 'number') {
            filter.query.country = country ? country : 0;
        } else if (typeof country === 'object' && !isEmpty(country)) {
            filter.query.country = { $in: country };
        } else if (typeof province === 'object' && !isEmpty(province)) {
            filter.query.province = { $in: province };
        }
        if (region && typeof region === 'number') {
            filter.query.region = region;
        }
        if (typeof province === 'number') {
            filter.query.province = province ? province : 0;
        }
        filter['excludeExcluded'] = excludeExcluded;
        filter['index'] = excludeExcluded;
        return this.http.post(environment.apiUrl + 'cities', this._globalFuntions.clearObject(filter));
    }

    getAllCities(search: string | number | object, province?: number | object | boolean): Observable<object> {
        const filter = { query: {} as any, options: {} as any };
        filter.options.populate = [{ path: 'province_data' }];
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search !== null) {
            filter.query.key = { $in: search };
        }
        if (typeof province === 'number') {
            filter.query.province = province;
        } else if (typeof province === 'object' && !isEmpty(province)) {
            filter.query.province = { $in: province };
        }
        return this.http.post(environment.apiUrl + 'cities/all', this._globalFuntions.clearObject(filter));
    }

    getLocationGroups(pagination: any, location: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}locationgroups?pagination=${pagination}&location=${location}`, { observe: 'response' as 'body' });
    }

    // tslint:disable-next-line: max-line-length
    getLocations(
        search: string | number | Array<number>,
        country?: number | boolean | object,
        region?: number | boolean | object,
        province?: number | boolean | object,
        city?: number | boolean | object,
        limit?: any,
        excludeExcluded: boolean = false
    ): Observable<object> {
        limit = limit ? limit : 30;
        const filter = { query: {} as any, options: { page: 1, limit: limit, sort: {} } as any };
        filter.options.populate = [{ path: 'city_data' }];
        if (isArray(search) && search.length) {
            filter.options.limit = search.length;
        }
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search !== null) {
            filter.query.key = { $in: search };
        }
        if (typeof country === 'number') {
            filter.query.country = country ? country : 0;
        }
        if (region && typeof region === 'number') {
            filter.query.region = region;
        }
        if (typeof province === 'number') {
            filter.query.province = province;
        } else if (typeof province === 'object' && !isEmpty(province)) {
            filter.query.province = { $in: province };
        }
        if (typeof city === 'number') {
            filter.query.city = city;
        } else if (typeof city === 'object' && !isEmpty(city)) {
            filter.query.city = { $in: city };
        }
        filter['excludeExclude'] = excludeExcluded;
        filter['index'] = excludeExcluded;
        return this.http.post(environment.apiUrl + 'locations', this._globalFuntions.clearObject(filter));
    }

    getAllLocations (search: string | number, country?: number | boolean | object, region?: number | boolean | object, province?: number | boolean | object, city?: number | boolean | object): Observable<object> {
        const filter = { query: {} as any };
        if (typeof search === 'string' && search) {
            filter.query.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.query.key = search;
        }
        if (typeof search === 'object' && search !== null) {
            filter.query.key = { $in: search };
        }
        if (typeof country === 'number') {
            filter.query.country = country ? country : 0;
        }
        if (region && typeof region === 'number') {
            filter.query.region = region;
        }
        if (typeof province === 'number') {
            filter.query.province = province;
        } else if (typeof province === 'object' && !isEmpty(province)) {
            filter.query.province = { $in: province };
        }
        if (typeof city === 'number') {
            filter.query.city = city;
        } else if (typeof city === 'object' && !isEmpty(city)) {
            filter.query.city = { $in: city };
        }
        return this.http.post(environment.apiUrl + 'locations/all', this._globalFuntions.clearObject(filter));
    }

    // getLocationGroups(pagination: any, location: any): Observable<object> {
    //     return this.http.get(`${environment.yiiUrl}locationgroups?pagination=${pagination}&location=${location}`, { observe: 'response' as 'body' });
    // }

    getUsers(name: any, support?: any, perPage?: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}users?user_name=${name}&support_user=${support}&per-page=${perPage}`, { observe: 'response' as 'body' });
    }

    getBackupUsers(search: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users?expand=agency_name,office_name,reports_to_data&support_user=false&search_word=` + search);
    }

    getTaskTypes(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}task_types`, filter);
    }

    getReporters(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/get-reporters`, { observe: 'response' as 'body' });
    }

    getOffices(search?: string, from?: string, exclude?: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}offices?search=${search}&from=${from}&exclude=${exclude}&per-page=false&sort[]=name&sort[]=SORT_ASC`, { observe: 'response' as 'body' });
    }

    getAgencies(for_openai_limits: boolean = false): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/get-all-agencies?for_openai_limits=${for_openai_limits}`, { observe: 'response' as 'body' });
    }
    getInvoicesAgencies(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/get-agencies`, { observe: 'response' as 'body' });
    }

    // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2856
    getSearchedListingAgency(name: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/get-searched-listing-agency?name=${name}`,{ observe: 'response' as 'body' });
    }

    getCompanies(search?: any, id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies?expand=contacts&search_key_words=${search}&id=${id}`, { observe: 'response' as 'body' });
    }

    getTaskGroups(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}task_groups/all?lang=${this._translateService.currentLang}`, filter);
    }

    getAssetTypes(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}commercial_asset_types/all?lang=${this._translateService.currentLang}`, filter);
    }

    getTiers(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}commercial_tiers/all?lang=${this._translateService.currentLang}`, filter);
    }

    getLandUses(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}commercial_land_uses/all?lang=${this._translateService.currentLang}`, filter);
    }

    getCommercialOperationalStrategies(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}commercial_operational_strategies/all?lang=${this._translateService.currentLang}`, filter);
    }

    getCommercialImportances(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}commercial_importances/all?lang=${this._translateService.currentLang}`, filter);
    }

    /* 
    CREATE MEETING COMPONENT 
    */

    getMeetingOffices(search: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}offices?search=${search}&dd=true&per-page=false`, { observe: 'response' as 'body' });
    }

    getMeetingTypes(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}meeting-types/active-meeting-types?system_lang=${this._translateService.currentLang}`, { observe: 'response' as 'body' });
    }

    getAccounts(search: any, id?: any, pagination?: any, include? : any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}accounts/load-accounts?ref=${search}&id=${id}&include=${include}&pagination=${pagination}`, { observe: 'response' as 'body' });
    }

    getOwners(search: any, id?: any, pagination?: any, include?: any): Observable<any> {
        if (this.currentUser.user_role === 'admin') {
            return this.http.get(`${environment.yiiUrl}owners/load-owners?ref=${search}&id=${id}&include=${include}&per-page=30`, { observe: 'response' as 'body' });
        } else {
            return this.http.get(`${environment.yiiUrl}owners/load-owners?ref=${search}&id=${id}&include=${include}&pagination=${pagination}`, { observe: 'response' as 'body' });
        }
    }

    getProperty(search: any, ref?: any, id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}properties/load-properties?ref=${search}&refs=${ref}&id=${id}`, { observe: 'response' as 'body' });
    }

    getCompany(search: any, id?: any, isownAgency?: boolean, type?:any, include?:any, saved_company?: string): Observable<any> {
        if (isownAgency) {
            return this.http.get(`${environment.yiiUrl}companies/load-companies?ref=${search}&saved_company=${saved_company}&id=${id}&agency=own&include=${include}`, { observe: 'response' as 'body' });
        }
        else if(type){
            return this.http.get(`${environment.yiiUrl}companies/load-companies?ref=${search}&saved_company=${saved_company}&id=${id}&type=${type}`, { observe: 'response' as 'body' });
        } else {
            return this.http.get(`${environment.yiiUrl}companies/load-companies?ref=${search}&saved_company=${saved_company}&id=${id}&include=${include}`, { observe: 'response' as 'body' });
        }
    }
    getVtProject(search: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}constructions?reference=${search}&expand=panoramic_images`, { observe: 'response' as 'body' });
    }
    getProject(search: any, id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}constructions?reference=${search}&id=${id}`, { observe: 'response' as 'body' });
    }

    getUrbanisation(search?: any, key?: any): Observable<any> {
        const filter = { query: {} as any, options: { page: 1, limit: 1000, value: 1 } };
        filter.query = { value: { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' } };
        if (key) {
            filter.query.$and = [{ key: { $in: key } }];
        }
        return this.http.post(`${environment.apiUrl}urbanisations/dropdown?exclude=excluded_urbanisations`, filter);
    }

    getSale(search?: string, id?: string): Observable<any> {
        const filter = {} as any;
        if (search) {
            // filter.search = {
            //     owner: search,
            //     account: search
            // };
        }
        if (id) {
            filter.id = id;
        }
        return this.http.post(`${environment.yiiUrl}sales/search?expand=property_data,account_data&per-page=false`, filter, { observe: 'response' as 'body' });
    }

    getRental(search: any, id?: any): Observable<any> {
        const filter = {} as any;
        if (id) {
            filter.id = id;
        }
        return this.http.post(`${environment.yiiUrl}rental-contracts/search?pagination=false&search=${search}`, filter, { observe: 'response' as 'body' });
    }

    getParticipants(name: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}activities/get-participants?support_user=false&name=${name}`, { observe: 'response' as 'body' });
    }

    getParticipantsAccount(name: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}activities/get-participants-account?support_user=false&name=${name}`, { observe: 'response' as 'body' });
    }

    /* CREATE TASK COMPONENT */

    getUsersByAgencies(search?: string, id?:string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/by-agency?support_user=false&per-page=20000&ref=${search}&id=${id}`, { observe: 'response' as 'body' });
    }

    usersExceptSupport(search?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/by-agency?support_user=false&ref=${search}`, { observe: 'response' as 'body' });
    }

    /* EMAILING */

    getUserEmails(search: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}users/emails?search=${search}`)
            .pipe(mapped((emails: any) => {
                if (this.currentUser.user_role === 'agent' || this.currentUser.user_role === 'agent_medium' || this.currentUser.user_role === 'admin_agent_light') {
                    const objEmails = map(emails, (email: any) => {
                        const dataobj = {} as any;
                        dataobj.org_email = email;
                        const stringVal = email.toString();
                        const hide = stringVal.split('@')[0].length - 2;
                        const replaced = new RegExp('.{' + hide + '}@', 'g');
                        dataobj.masked_email = stringVal.replace(replaced, '***@');
                        return dataobj;
                    });
                    return objEmails;
                } else {
                    return emails;
                }
            }));
    }

    getEmailsTemplates(search: string, type?: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}emails?search_text=${search}&type=${type}&pagination=false`);
    }

    /* CREATE VIEWINGS COMPONENT */

    getLanguages(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}languages/for-user?system_lang=${this._translateService.currentLang}`, { observe: 'response' as 'body' });
    }

    getCompanySearch(search: any, id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/load-companies?search=${search}&id=${id}`, { observe: 'response' as 'body' });
    }

    getParticipantsViewings(name: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}activities/get-participants?support_user=false&name=${name}&support_user=false`, { observe: 'response' as 'body' });
    }

    getCompanyContacts(_id: any, search?: any): Observable<any> {
        search = search ? search : '';
        return this.http.get(`${environment.yiiUrl}companies/load-contacts?company_id=${_id}&search=${search}`, { observe: 'response' as 'body' });
    }

    getOwnersProperty(search: any, filter?: any, ids?: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}properties/load-properties?ref=${search}&ids=${ids}&expand=images`, filter ? filter : null, { observe: 'response' as 'body' });
    }

    /*viewings search*/

    getByAgency(search?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/by-agency?ref=${search}&support_user=false`, { observe: 'response' as 'body' });
    }

    /* CREATE OFFERS */

    getProperties(search?: any, id?: any, ltr?: any): Observable<any> {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${environment.yiiUrl}properties/load-properties?id=${id}&ref=${search}&lt_rental=${ltr}&expand=p_agency_data,listing_agent_data,agency_logo,p_owner,shared_agency_ids,p_agency_name,p_buckets,p_user_name,documents,property_type,property_country,property_location,property_region,property_city,property_province,property_urbanisation`, { observe: 'response' as 'body' });
    }

    getCommercialProperties(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        if(!filter.status && !filter?.$or) {
            Object.assign(filter, {status: {$in:['Available','Under Offer', 'Valuation']}})
        }
        return this.http.post(`${environment.apiUrl}commercial_properties/dropdown`, filter, { observe: 'response' as 'body' });
    }

    getPropertyTypeInArray(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.yiiUrl}property-types/in-array`, filter, { observe: 'response' as 'body' });
    }
    getCommercialTypeInArray(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}commercial_types/in-array`, filter, { observe: 'response' as 'body' });
    }

    getOffersAgency(id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/by-agency?user_agency=${id}&support_user=false`, { observe: 'response' as 'body' });
    }

    getOffersAccount(search?: any, id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}accounts/load-accounts?ref=${search}&id=${id}`, { observe: 'response' as 'body' });
    }

    getSources(relatesTo: string, search?: string, perPage?: any): Observable<any> {
        perPage = perPage ? perPage : 'false';
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${environment.yiiUrl}sources?relates_to=${relatesTo}&status=Active&search=${search}&per-page=${perPage}&system_lang=${this._translateService.currentLang}`,
            { observe: 'response' as 'body' });
    }

    getAllSources(search?: any, related?: any): Observable<any> {
        const postData = {} as any;
        if (search) {
            postData.search_word = search;
        }
        if (related) {
            postData.relates_to = related;
        }
        postData.sort = [`value.${this._translateService.currentLang}`, 'SORT_ASC'];
        return this.http.post(`${environment.yiiUrl}sources/search?per-page=false`, postData);
    }

    /* Property types */
    getPropertyTypes(search?: string): Observable<any> {
        const filter: any = { parent: { $exists: false } };
        if (search) {
            filter.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        return this.http.post(`${environment.apiUrl}property_types/all?system_lang=${this._translateService.currentLang}`, filter);
    }

    getPropertySubTypes(ids?: any, search?: any): Observable<any> {
        ids = (ids && ids.length) ? ids : [];
        const filter: any = { parent: { $in: ids } };
        if (typeof search === 'string' && search) {
            filter.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'object' && search) {
            filter.key = { $in: search };
        }
        return this.http.post(`${environment.apiUrl}property_types/all?system_lang=${this._translateService.currentLang}`, filter);
    }

    /* location groups */

    getLocationsGroup(search?: any): Observable<any> {
        const filter = { query: {} as any, options: { page: 1, limit: 30, sort: { value: 1 } } };
        const lang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        if (search) {
            filter.query = {
                $or: [
                    { 'value.en': { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' } },
                ]
            };
            if (lang !== 'en') {
                filter.query.$or.push(
                    { [`value.${lang}`]: { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' }}
                )
            }
        }
        return this.http.post(`${environment.apiUrl}locationgroups?system_lang=${lang}`, filter);
    }

    /* CREATE SALES */

    getPropertyOffers(search?: any, related?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}offers/get-property-offers?search=${search}&expand=property_data,account_data,agency_data,accepted_offer&related_to=${related}`,
            { observe: 'response' as 'body' });
    }

    getSalesProperties(search: any, status?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}properties/load-properties?ref=${search}&expand=p_agency_data,listing_agent_data&status=${status}`,
            { observe: 'response' as 'body' });
    }

    getSpecificSalesProperties(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}properties/load-properties?ref=${id}&expand=p_agency_data,listing_agent_data`,
            { observe: 'response' as 'body' });
    }

    getSalesCompany(search: any, id?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/load-companies?ref=${search}&id=${id}&expand=contacts&type=Agency&pagination=false`,
            { observe: 'response' as 'body' });
    }

    getLeadStatuses(search?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}lead-statuses?search=${search}&per-page=false&sort=true`);
    }

    getMasterdataTypes(): Observable<any> {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${environment.yiiUrl}masterdata-types?pagination=false&for=Companies&system_lang=${this._translateService.currentLang}`, { observe: 'response' as 'body' });
    }

    getCompanyTypes(search: any, type: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/load-companies?search=${search}&type=${type}&pagination=false`,
            { observe: 'response' as 'body' });
    }

    getContacts(search: any, id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/load-contacts?search=${search}&company_id=${id}&pagination=false`,
            { observe: 'response' as 'body' });
    }

    /* CREATE URBANISATIONS */

    getAgents(search: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users?selected_data=true&support_user=false&user_name=${search}&per-page=false`,
            { observe: 'response' as 'body' });
    }

    getUrbanCompanies(search: any, type: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies?search_key_words=${search}&selected_data=true&type=${type}`,
            { observe: 'response' as 'body' });
    }

    getPropertyType(search?: any): Observable<any> {
        search = search ? search : '';
        return this.http.get(`${environment.yiiUrl}property-types/non-parent?expand=sub_type&type_for_not=3&system_lang=${this._translateService.currentLang}&search=${search}`,
            { observe: 'response' as 'body' });
    }

    getPropertyTypeByParent(systemLang: any, parent: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}property-types/by-perant?parent=${parent}&type_for_not=3&system_lang=${this._translateService.currentLang}`,
            { observe: 'response' as 'body' });
    }

    /* GET LISTING AGENCY */

    getListingAgency(name?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/get-companies-type-agency?name=${name}`,
            { observe: 'response' as 'body' });
    }

    getUserAgency(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/get-user-agency`,
            { observe: 'response' as 'body' });
    }

    /* GET LISTING AGENTS */
    getListingAgents(userName: any, agency = ''): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users?user_name=${userName}&agency=${agency}&support_user=false&per-page=false`,
            { observe: 'response' as 'body' });
    }
    /*CREATE BOOKINGS*/

    getClientAccount(name: any, id?: any): Observable<any> {
        id = id ? id : 'undefined';
        return this.http.get(`${environment.yiiUrl}accounts?search[]=name&search[]=${name}&id=${id}&sort[]=forename&sort[]=SORT_ASC`,
            { observe: 'response' as 'body' });
    }

    getRentalProperties(name?: any, id?: any, model?: any, schedule?: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}bookings/load-rental-properties?&ref=${name}&id=${id}&modelName=${model ? model : ''}&schedule=${schedule}&date_from=&date_until=`,
            { observe: 'response' as 'body' })
            .pipe(mapped((data: any) => {
                for(var key in data.body)
                    data.body[key].label = (data.body[key].type_one ? (' - '+data.body[key].type_one) : '')  + (data.body[key]?.location ? (' - '+data.body[key].location) : '' ) + (data.body[key]?.property_name ? (' - '+data.body[key].property_name) : '');
                return data;
            }));
    }

    getBookingCompanies(name: any, id?: any, cleaning = false): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies?cleaning=${cleaning}&expand=contacts&search_key_words=${name}&id=${id}&sort[]=commercial_name&sort[]=SORT_ASC`,
            { observe: 'response' as 'body' });
    }

    getBookingExtras(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}bookings-extras?page_size=false&p_id=`,
            { observe: 'response' as 'body' });
    }

    getBookingCleaning(id = ''): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cleaning/subjects?p_id=${id}`,
            { observe: 'response' as 'body' });
    }

    getNationalities(lang?: any, search?: any): Observable<any> {
        if (lang && search) {
            return this.http.get(`${environment.yiiUrl}nationalities?lang=${lang}&search_word=${search}&page=1&per-page=1000`,
                { observe: 'response' as 'body' });
        }
        else {
            return this.http.get(`${environment.yiiUrl}nationalities?page=1&per-page=1000`,
                { observe: 'response' as 'body' });
        }
    }

    getContract(): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}contract_genrator`, {});
    }

    /* BUILDING STYLES */

    getBuildingStyle(search?: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}building-styles?pagination=false&for=residential&search=${search}`,
            { observe: 'response' as 'body' });
    }

    getBuildingStyles(search?: any, type?: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}building-styles?pagination=false`,
        // return this.http.get(`${environment.yiiUrl}building-styles?pagination=false&for=${type ? type : 'residential'}`,
            { observe: 'response' as 'body' });
    }

    getSharingLevels(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}sharing-levels`,
            { observe: 'response' as 'body' });
    }

    getSharedAgency(name?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}properties/get-shared-agency?name=${name}`,
            { observe: 'response' as 'body' });
    }

    getFilterBookings(search: any, page: any): Observable<any> {
        return this.http.post<object>(`${environment.yiiUrl}bookings/filter-bookings?expand=account_data,p_data&page=${page}
        &status=&sort[]=&sort[]=` , search);
    }
    getWorkOdersTypes(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}wo_types/all?lang=${this._translateService.currentLang}`, filter);
    }


    /* ACCOUNTS */

    getAccountsBuildings(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}building-styles?pagination=false?lang=${this._translateService.currentLang}`, { observe: 'response' as 'body' });
    }

    getMooringTypes(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}mooring_types?exclude=excluded_mooring_types`, filter);
    }

    getCommercialTypes(search?: any): Observable<any> {
        const filter = { parent: { $exists: false } } as any;
        if (typeof search === 'string' && search) {
            filter.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.key = search;
        }
        if (typeof search === 'object' && search) {
            filter.key = { $in: search };
        }
        return this.http.post(`${environment.apiUrl}commercial_types/all?lang=${this._translateService.currentLang}`, filter);
    }

    getCommercialTypesTwo(data: any, search?: any): Observable<any> {
        const filter = { parent: { $in: data } } as any;
        if (typeof search === 'string' && search) {
            filter.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        if (typeof search === 'number' && search) {
            filter.key = search;
        }
        if (typeof search === 'object' && search) {
            filter.key = { $in: search };
        }
        return this.http.post(`${environment.apiUrl}commercial_types/all?lang=${this._translateService.currentLang}`, filter);
    }

    getPropertyCategory(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}property-categories?per-page=false&lang=${this._translateService.currentLang}`, { observe: 'response' as 'body' });
    }

    getCustomCategories(search): Observable<any> {
        return this.http.post<any[]>(`${environment.apiUrl}custom_categories/all`, search, { observe: 'response' as 'body' });
    }
    /* OWNERS: GET COMPANIES */
    getCompaniesList(search, notType?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}companies/load-companies?search=${search}&pagination=false&type_no_agency=${notType}`,
            { observe: 'response' as 'body' });
    }
    getAccountsCompanies(search): Observable<any> {
        return this.http.get(`${environment.yiiUrl}accounts/load-companies?ref=${search}&id=undefined&expand=contacts&pagination=false&index=false`,
            { observe: 'response' as 'body' });
    }
    getOwnerLeadStatuses(search: any): Observable<any> {
        return this.http.post<any[]>(`${environment.apiUrl}owner_lead_statuses`, search);
    }


    /* PROPERTIES: GET PRIVATE OBJECT OWNERS */
    getPropertyOwners(_data: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}owners/owners-for-properties?ref=${_data}`,
            { observe: 'response' as 'body' });
    }

    getAgencyUsers(search: string): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}users?search=${search}&support_user=false&selected_data=true&per-page=false`);
    }

    getAllAgencyUsers(search: string): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}users?search=${search}&support_user=false&selected_data=true&per-page=false&all=true`);
    }

    getDocumentsTypes(search?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}documents-types?search=${search}&pagination=false`);
    }

    /* GET LOCATION GROUPS */
    getLocationsGroups(search?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}locationgroups/load-in-accounts?page=1&per-page=1000&search=${search}`);
    }

    getLocationsFromGroup(keys?: any, limit?: any): Observable<any> {
        limit = limit ? limit : 50;
        const filter = { query: {} as any, options: { page: 1, limit: limit, sort: {} } };
        filter.options.sort['value.' + this._translateService.currentLang] = 1;
        if (keys) {
            filter.query.$and = [{ key: { $in: keys } }];
        }

        return this.http.post<any[]>(`${environment.apiUrl}locations?exclude=excluded_locations`, filter);
    }

    /* PROPERTIES: PHASES */
    getConstructonPhases(_id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}constructions/load-phases?construction=${_id}`);
    }

    getPropertiesPDF(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}properties/search?expand=property_location,agency_logo,images,owner_data,property_type`, data,
            { observe: 'response' as 'body' });
    }

    getConstructionPDF(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}constructions/search?expand=Location_data,images,P_owner,Types_data`, data,
            { observe: 'response' as 'body' });
    }
    getConstructions(search?: string): Observable<object> {
        search ? search = search : search = '';
        return this.http.get(`${environment.yiiUrl}constructions?reference=${search}`);
    }
    getLicenseTypes(search?: string): Observable<any> {
        const queryData = {};
        const optionsData = { page: 1, limit: 1000, sort: { key: 1 } };
        // if (search){
        //     //  queryData = { 'value.en': {$regex: '.*' + search + '.*' , $options: search }  };
        //     queryData = { };
        // }
        // else{
        //     queryData = { }; 
        // }

        return this.http.post<any[]>(`${environment.apiUrl}license_types`, { query: queryData, options: optionsData });
    }

    /*get Companies and Contacts => Properites */
    getCompaniesData(search?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}companies/by-name?name=${search}`);
    }
    getCompaniesContacts(id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}companies/contacts?id=${id}`, { observe: 'response' as 'body' });
    }
    /******      ******/

    getCurrencies(): Observable<any> {
        return of(this.currencies);
    }
    
    getFeedSystemArr(): Observable<any> {
        return of(this.feedSystemsArr);
    }

    propDocumentsTypes(): Observable<object> {
        return this.http.get(`${environment.yiiUrl}documents-types?per-page=false&system_lang=${this._translateService.currentLang}`);
    }

    /** Virtual Tour Search Properties */

    getVtProperties(ref: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}properties/load-properties?ref=${ref}&vt=true`);
    }
    /** Virtual Tour Commercial */
    getVtCommercial(filter?: any, ref?: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}commercial_properties/dropdown?panoramic_images=true&ref=${ref}`, filter);
    }
    /** Virtual Tour Mooring */
    getVtMooring(filter?: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}mooring_properties/dropdown?panoramic_images=true`, filter);
    }

    /***** ******/
    getAllRtypes(filter?: any): Observable<object> {
        return this.http.post(`${environment.apiUrl}room_types/all`, filter);
    }
    getProperyViewCount(id: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties-view-count/get-count?property_id=${id}`);
    }

    usersAssignee(office: any, search?: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}users?search=${search}&offices=${office}&per-page=false&support_user=false`);
    }

    getContractsProperty(search: any, id: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/load-properties?ref=${search}&lt_rental=true&pre_load=${id}`);
    }

    getPaymentMethods(): Observable<object> {
        return this.http.post(`${environment.apiUrl}payment_methods/all`, {});
    }

    /*** Rental Properties **/
    getRenatlProperties(search: any, owner: any, model?: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}reporting/rental-properties?search=${search}&owner=${owner}&modelName=${model ? model : ''}`);
    }

    getLanguagesFor(languagesFor: string): Observable<any> {
        return this.http.post(`${environment.yiiUrl}languages/search?pagination=false`, { for: languagesFor });
    }

    // listed price
    getFormates(name: string, cRef: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}list-formates?page=1&name=${name}&c_ref=${cRef}`);
    }
    // all objects with lat long
    getCommercialWithAllLatlng(filter?: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}commercial_properties/all-with-lat-lang`, filter);
    }

    /// excluded_properties: location
    getExcludedLocations(limit?: any, search?: string): Observable<any> {
        limit = limit ? limit : 50;
        const filter = { query: {} as any, options: { page: 1, limit: limit, sort: {} } };
        filter.options.sort['accent_value.' + this._translateService.currentLang] = 1;
        if (search) {
            filter.query['accent_value.' + this._translateService.currentLang] = { $regex: '.*' + search + '.*', $options: 'i' };
        }
        filter.query['country'] = 1;
        return this.http.post<any[]>(`${environment.apiUrl}locations?exclude=excluded_locations`, filter);
    }

    getTouristCategories(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.apiUrl}tourist_categories/all`, data);
    }

    loadCompanies(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.yiiUrl}companies/search`, data);
    }

    getCMSPages(search: any, id: any, lang: any): Observable<any> {
        const filter = { title: search };
        return this.http.post<any[]>(`${environment.yiiUrl}cms-pages/search?&expand=host&type=page&page=page&site_id=${id}&lang=${lang}`, filter);
    }
    getRestaurationLicences(data?: any): Observable<object> {
        return this.http.post(`${environment.apiUrl}restauration_licences`, data);
    }
    getLocationGroupFilters(): Observable<object> {
        return this.http.get(`${environment.yiiUrl}locationgroups/lgms`);
    }
    getAgencyOffices(id: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}offices?agency=${id}&per-page=false&sort[]=name&sort[]=SORT_ASC`);
    }
    getPropertiesOffices(search?: string): Observable<any> {
        return this.http.get(`${environment.yiiUrl}offices?search=${search}&dd=true&per-page=false&sort[]=name&sort[]=SORT_ASC`, { observe: 'response' as 'body' });
    }

    getUserAgencies(search: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}agencies?search_key_words=${search}`);
    }

    getOwnCompanies(search: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}companies/load-companies?ref=${search}&expand=contacts&type=Agency&pagination=false&agency=own`, {});
    }
    getAllTaskTypes(filter?: any): Observable<object> {
        const currentLang = this._translateService.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        return this.http.post<any[]>(`${environment.apiUrl}task_types/all?lang=${currentLang}`, filter);
    }
    getLocationByKey(key?: any): Observable<object> {
        return this.http.post<any[]>(`${environment.apiUrl}locations/view/${key}`, '');
    }
    getCityByKey(key?: any): Observable<object> {
        return this.http.post<any[]>(`${environment.apiUrl}cities/view/${key}`, '');
    }
    getPdfByHtml(data: any): Observable<object> {
        return this.http.post<any[]>(`${environment.yiiUrl}pdf/html-to-pdf?template_id=cleaning_order`, data);
    }
    getBookingWorkodersPdf(data?: any, filter?: any): Observable<object> {
        return this.http.post<any[]>(`${environment.yiiUrl}bookings-work-oders/generate-pdf`, [data, filter]);
    }
    sendBookingWorkodersPdfEmail(file?: any, mailPdf?: any): Observable<object> {
        return this.http.post<any[]>(`${environment.yiiUrl}bookings-work-oders/pdf-email?file_name=${file}`, mailPdf);
    }

    // Bookings -> Transit
    getSortedCompanies(search): Observable<object> {
        return this.http.get(`${environment.yiiUrl}companies?search_key_words=${search}&sort[]=commercial_name&sort[]=SORT_ASC`);
    }
    getAllPropertyForShowMap(filter?: any): Observable<object> {
        return this.http.post(`${environment.apiUrl}all-show-map`, filter);
    }
    getAllPropertyForShowOnMap(filter?: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}properties/all-show-map`, filter);
    }

    rentalPeriods(): Observable<object> {
        return this.http.get(`${environment.yiiUrl}rental-periods?pagination=false`);
    }
    clearRedisByKey(key: any): Observable<object> {
        const agency = JSON.parse(localStorage.getItem('currentAgency'));
        console.log(agency._id);
        return this.http.get(`${environment.frontendUrl}index.php?r=site/clear-redis-cache&key=${key}_` + agency._id);
    }
    // companies and owner
    getClassification(search?: any, currentRoute?:any): Observable<object> {
        if(typeof(currentRoute) == 'undefined') {
            if(includes(this.router.url, 'owners')) {
                currentRoute = 'owner';
            }else {
                currentRoute = 'company';
            }
        }
        let routeData=currentRoute;
        const filter = {} as any;
        if (search) {
            filter.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        return this.http.post(`${environment.apiUrl}classifications/all/${routeData}?system_lang=${this._translateService.currentLang}`, filter);
    }

    getAccountClassification(search?: any): Observable<object> {
        const filter = {} as any;
        if (search) {
            filter.$or = map(this.siteLanguages, (lang) => {
                const returnData = {};
                returnData[`value.${lang.id}`] = { $regex: '.*' + this._globalFuntions.caseAndAccentInsensitive(search) + '.*', $options: 'i' };
                return returnData;
            });
        }
        return this.http.post(`${environment.apiUrl}account_classifications/all?system_lang=${this._translateService.currentLang}`, filter);
    }

    agencyUsers(search?: string, data?: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}users/by-agency?support_user=false&ref=${search}`, data);
    }
    getRelatedEmails(id: string, page?: any, filter?: any, model?: any): Observable<any> {
        filter = filter ? filter : '';
        page = page ? page : 1;
        return this.http.get<any>(`${environment.yiiUrl}properties/get-related-emails?id=${id}&page=${page}&expand=properties_data&duration=${filter}&model=${model}`);
    }
    getConstructionByRef(ref: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.yiiUrl}constructions?construction_ref=${ref}`)
            .pipe(mapped(data => {
                return data;
            }));
    }
    savePropertiesHistory(id: any, ref: any, data: any, model: any): Observable<any> {
        return this.http.post<any[]>(`${environment.yiiUrl}properties/save-history?id=${id}&reference=${ref}&model=${model}`, data);
    }
    getMoorings(modelType, filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}${modelType}/dropdown`, filter, { observe: 'response' as 'body' })
        .pipe(mapped((data: any) => {
            for(var key in data.body)
                if(modelType == 'boats'){
                    data.body[key].label= data.body[key].reference + (data.body[key]?.boat_type ? (' - '+data.body[key].boat_type.value[this._translateService.currentLang]) : '') + (data.body[key]?.property_city ? (' - '+data.body[key].property_city.value[this._translateService.currentLang]) : '');
                }else{
                    data.body[key].label= data.body[key].reference + (data.body[key]?.type_one ? (' - '+data.body[key].type_one) : '') + (data.body[key]?.property_city ? (' - '+data.body[key].property_city.value[this._translateService.currentLang]) : '');
                }
            return data;
        }));
    }
    // getBoats(filter?: any): Observable<any> {
    //     filter = filter ? filter : {};
    //     return this.http.post(`${environment.apiUrl}boats/dropdown`, filter, { observe: 'response' as 'body' })
    //     .pipe(mapped((data: any) => {
    //         for(var key in data.body)
    //             data.body[key].label= data.body[key].reference + (data.body[key]?.type_one ? (' - '+data.body[key].type_one) : '') + (data.body[key]?.property_city ? (' - '+data.body[key].property_city.value[this._translateService.currentLang]) : '');
    //         return data;
    //     }));;
    // }
    getCommercialCategories(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}commercial_categories/dropdown`, filter, { observe: 'response' as 'body' });
    }
    getMooringBoatCategories(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}custom_categories/dropdown`, filter, { observe: 'response' as 'body' });
    }
    getAllBoatTypes(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}boat_types/all`, filter, { observe: 'response' as 'body' });
    }
    getBoatTypes(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}boat_types/dropdown`, filter, { observe: 'response' as 'body' })
        .pipe(mapped((data: any) => {
            for(var key in data.body)
                data.body[key].label= data.body[key].value[this._translateService.currentLang];
            return data;
        }));
    }
    getBoatBuilders(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}boat_builders/all`, filter, { observe: 'response' as 'body' });
    }
    getMakers(filter?: any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.apiUrl}make_names/all`, filter, { observe: 'response' as 'body' });
    }
    // get master office data
    getListOfMasterAndSubOffices(filter? : any): Observable<any> {
        filter = filter ? filter : {};
        return this.http.post(`${environment.yiiUrl}offices/master-offices`, filter, { observe: 'response' as 'body' });
    }
}
