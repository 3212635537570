// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-actions {
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/app/components/account-delete/inactive-confirmation/inactive-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ","sourcesContent":[".mat-mdc-dialog-actions {\n    justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
