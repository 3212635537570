import { Component, OnInit, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TasksCreateFormComponent } from '../tasks-create/tasks-create-form/tasks-create-form.component';
import { TasksService } from 'app/services';
import { isEmpty, pickBy, identity }  from 'lodash';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tasks-create-modal',
  templateUrl: './tasks-create-modal.component.html',
  styleUrls: ['./tasks-create-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TasksCreateModalComponent implements OnInit {

  data: any = {};
  toggle = false as boolean;
  isClick = false as boolean;
  @ViewChild(TasksCreateFormComponent, { static: true }) tasksCreateForm: TasksCreateFormComponent;
  borderColor: string;

  constructor(
    public matDialogRef: MatDialogRef<TasksCreateModalComponent>,
    private _tasksService: TasksService,
    private _timeLineData: TimeLineDataService,
    private _fuseSidebarService: FuseSidebarService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    if (!isEmpty(this._data)) {
      this.data = this._data;
      if (this.data && this.data.data && this.data.data.color) {
        this.borderColor = this.data.data.color;
      } else if (this.data && this.data.color) {
        this.borderColor = this.data.color;
      }
    }
  }

  ngOnInit(): void {
    if (!isEmpty(this.data)) {
      if (this.data.relatedTo === 'update') {
        setTimeout(() => {
          if (this.data._id) {
            this._tasksService.getById(this.data._id).subscribe((data: any) => {
              this.translateSubject(data);            
              this.tasksCreateForm.activity.patchValue(data);
              this.tasksCreateForm.patchFields(data);
            });
          }
          else {
            this.tasksCreateForm.activity.patchValue(this.data.data);
            this.tasksCreateForm.patchFields(this.data.data);
          }
        }, 200);
      } else if (this.data.relatedTo === 'calendar') {
        setTimeout(() => {
          this.tasksCreateForm.activity.get('start_date').setValue(this.data.date);
          this.tasksCreateForm.activity.get('end_date').setValue(this.data.date);
        }, 200);
      } else if (this.data.relatedTo === 'accounts') {
        setTimeout(() => {
          this.tasksCreateForm.task.controls[0].get('related_to').setValue('Account');
          this.tasksCreateForm.task.controls[0].get('account').setValue(this.data.accountId);
          this.tasksCreateForm.loadAccount(this.data.accountId);
        }, 200);
      } else if (this.data.relatedTo === 'owners') {
        setTimeout(() => {
          this.tasksCreateForm.task.controls[0].get('related_to').setValue('Owner');
          this.tasksCreateForm.task.controls[0].get('owner').setValue(this.data.ownerId);
          this.tasksCreateForm.loadOwner(this.data.ownerId);
        }, 200);
      } else if (this.data.relatedTo === 'commercial_properties') {
        setTimeout(() => {
          this.tasksCreateForm.task.controls[0].get('related_to').setValue('Commercial Properties');
          this.tasksCreateForm.task.controls[0].get('commercial_property').setValue(this.data.accountId);
        }, 200);
      }  
      else {

        if (this.data._id) {
          this.data = this.data._id;
        }
        this._tasksService.getById(this.data).subscribe((data: any) => {
          this.translateSubject(data);
          // this.tasksCreateForm.viewRecord = true;
          this.tasksCreateForm.activity.patchValue(data);
          this.tasksCreateForm.patchFields(data);
        });
      }
    }
  }
  
  //Replace subject keywords to its translations
  translateSubject(data): void {
    if (data?.type === 'task' && data?.subject?.includes('next_contact_title')) {
      data.subject = data.subject.replace('next_contact_title', this._translateService.instant('next_contact_title'));
    }
  }

  submitForm(): void {
    if (this.tasksCreateForm.activity.valid) {
      if (this.borderColor) {
        this.tasksCreateForm.activity.get('color').setValue(this.borderColor);
      }
      const subject = this.tasksCreateForm.activity.get('subject').value;
      if (!isEmpty(subject)) {
        const translatedKeyword = this._translateService.instant('next_contact_title');
        if(subject?.includes(translatedKeyword)){
          this.tasksCreateForm.activity.get('subject').setValue(subject?.replace(translatedKeyword, 'next_contact_title'));
        }
      }
      if (!isEmpty(this.data)) {
        this.isClick = true;
        if (this.data.relatedTo === 'update') {
          if (this.borderColor) {
            this.tasksCreateForm.activity.get('color').setValue(this.borderColor);
          }
          let id = '';
          if(this.data && this.data._id && this.data._id != ''){
            id = this.data._id;
          } else if(this.data && this.data.data && this.data.data._id && this.data.data._id != ''){
            id = this.data.data._id;
          }
          this._tasksService.update(pickBy(this.tasksCreateForm.activity.getRawValue(), identity), id).subscribe((data: any) => {
            this.matDialogRef.close('refresh');
            this.isClick = false;
          }, (err: any) => {
            this.isClick = false;
          });
        }
        /**
         * In this.data i see that task id was comming from dasboard activites tab which was causing duplicate of tasks
         */
        else if ((typeof this.data) === 'string') {
          this._tasksService.update(pickBy(this.tasksCreateForm.activity.getRawValue(), identity), this.data).subscribe((data: any) => {
            this.matDialogRef.close('refresh');
          });
        }
        else {
          this.isClick = true;
          this._tasksService.create(pickBy(this.tasksCreateForm.activity.getRawValue(), identity)).subscribe((data: any) => {
            this.matDialogRef.close('refresh');
          }, (err: any) => {
            this.isClick = false;
          });
        }
      } else {
        this.isClick = true;
        this._tasksService.create(pickBy(this.tasksCreateForm.activity.getRawValue(), identity)).subscribe((data: any) => {
          this.matDialogRef.close('refresh');
          this._snackBar.open(this._translateService.instant('Created successfully'), this._translateService.instant('Close'), {
            duration: 2000,
          });
        }, (err: any) => {
          this.isClick = false;
        });
      }
    } else {
      this.tasksCreateForm.activity.markAllAsTouched();
    }


  }
  onOpenTimeline(event: any): void {
    // event._id = this.route.snapshot.paramMap.get('id');
    this._timeLineData.setModelDataValue(event);
    this._timeLineData.setRelatedToValue('activities');
    this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
  }

}
